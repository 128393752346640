import { createSlice } from "@reduxjs/toolkit";
import { groupByProperty } from "../../utils/timesUtils";
import { getDateFromISOFormat } from "../../../SharedModule/utils/dateUtils";
import { SummaryByTask, SummaryByTaskType, SummaryByUser } from "../../types";

type TimesState = {
  isAdmin: boolean;
  isLoading: boolean;
  summary: {
    total: string;
    billable: string;
    nonBillable: string;
    internal: string;
    timeOff: string;
  };
  summaryByUser: SummaryByUser[] | null;
  summaryByTask: SummaryByTask[] | null;
  summaryByTaskType: SummaryByTaskType[] | null;
  queryResultList: object | null;
};

const initialState: TimesState = {
  isAdmin: false,
  isLoading: true,
  summary: {
    total: "—",
    billable: "—",
    nonBillable: "—",
    internal: "—",
    timeOff: "—",
  },
  summaryByUser: null,
  summaryByTask: null,
  summaryByTaskType: null,
  queryResultList: null,
};

export const queryResultsSlice = createSlice({
  name: "queryResults",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadQueryResultsInfo: (state, action) => {
      state.summary = action.payload.summary;
      state.summaryByUser = action.payload.summaryByUser;
      state.summaryByTask = action.payload.summaryByTask;
      state.summaryByTaskType = action.payload.summaryByTaskType;
      // set some IDs to help super admin to select/unselect multiples
      if (action.payload.result && action.payload.result.length > 0){
        let i = 1;
        action.payload.result.forEach((element) => {
          element.id = i;
          i += 1;
          // to avoid issue when editing from results page
          element.entryDate =
            getDateFromISOFormat(element.entryDate);
        });
        state.queryResultList = groupByProperty(
          action.payload.result,
          "entryDate"
        );
      } else {
        state.queryResultList = null;
      }
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, loadQueryResultsInfo } = queryResultsSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.queryResults.isLoading;
};

export const Summary = (state: any) => {
  return state.queryResults.summary;
};

export const GetSummaryByUser = (state: any) => {
  return state.queryResults.summaryByUser;
};

export const GetSummaryByTask = (state: any) => {
  return state.queryResults.summaryByTask;
};

export const GetSummaryByTaskType = (state: any) => {
  return state.queryResults.summaryByTaskType;
};

export const QueryResultList = (state: any) => {
  return state.queryResults.queryResultList;
};

export default queryResultsSlice.reducer;
