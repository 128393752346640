import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { BiInfoCircle, BiLockAlt, BiCheckCircle } from "react-icons/bi";
import { Select } from "../../SharedModule/components/Select";
import { Calendar } from "../../SharedModule/components/Calendar";
import { useApprovalTime } from "../hooks/useApprovalTime";
import {
  addElementToArray,
  getCostCenterChildrenIds,
  hoursTypeOptions,
} from "../utils/timesUtils";
import { useEffect, useState } from "react";
import {
  APPROVED_TEXT,
  LOCKED,
  ROUTE_APPROVE_TIME_TIMESHEETS,
  ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER,
  TIME_ADMIN_ACCESS,
} from "../../SharedModule/utils/constants";
import {
  dateFormatEntryEdit,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import {
  InfoApprovalTime,
  ProjectManagerOrUser,
  UserToApprove,
} from "../types";
import {
  changeOrder,
  loadTimeEntriesInfo,
  resetFilters,
  SelectCostCentersApprovalTime,
  SelectCostCentersApprovalTimeComplete,
  SelectFilters,
  SelectIsInfoApprovalLoaded,
  SelectManagersApprovalTime,
  selectOrderCriteria,
  SelectProjectsApprovalTime,
  setCostCentersApprovalTimeComplete,
  setFilters,
  setInfoApproval,
  setLoading,
} from "../redux/reducers/approvalTime.reducer";
import { useDispatch, useSelector } from "react-redux";
import { TimesheetsService } from "../services/timesheets";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { Spinner } from "../../SharedModule/components/Spinner";
import {
  actualUIState,
  setActiveTab,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setSearchState } from "../redux/reducers/approveTimeByUser.reducer";
import { verifyModuleAccess } from "../../SharedModule/utils/accessUtils";
import Footer from "../../SharedModule/components/Footer";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";
import { NoContent } from "../../SharedModule/components/NoContent";
import image from "./../../styles/legacy/404.png";
import { checkUrlParam } from "../../SharedModule/utils/headerUtils";

export const ApproveTimePage = () => {
  const filters = useSelector(SelectFilters);
  const costCentersApprovalTime = useSelector(SelectCostCentersApprovalTime);
  const costCentersApprovalTimeComplete = useSelector(
    SelectCostCentersApprovalTimeComplete
  );
  const projectsApprovalTime = useSelector(SelectProjectsApprovalTime);
  const managersApprovalTime = useSelector(SelectManagersApprovalTime);
  const isInfoApprovalLoaded = useSelector(SelectIsInfoApprovalLoaded);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ASUITE11-2698
  const [projectsApprovalTimeFiltered, setProjectsApprovalTimeFiltered] =
    useState(projectsApprovalTime ?? []);

  let [searchParams] = useSearchParams();

  const sortBy = useSelector(selectOrderCriteria);

  // Local info to set filters at first load
  const [localInfoApproval, setLocalInfoApproval] = useState<InfoApprovalTime>({
    costCentersApprovalTime: costCentersApprovalTime,
    projectsApprovalTime: projectsApprovalTime,
    managersApprovalTime: managersApprovalTime,
  });

  // times module admin
  const { userRolesTimesModule, isLoadingUserInfo } =
    useSelector(actualUIState);

  // ASUITE11-2614: Approve Time from the Project (link to The Hub) - FE
  // send params values to calculate in first load
  let projectFilterByParam: any = null;
  if (
    checkUrlParam(searchParams.get("projectId")) &&
    localInfoApproval.projectsApprovalTime
  ) {
    projectFilterByParam = localInfoApproval.projectsApprovalTime.find(
      (elem) => elem.value === Number(searchParams.get("projectId"))
    )
      ? [
          localInfoApproval.projectsApprovalTime.find(
            (elem) => elem.value === Number(searchParams.get("projectId"))
          ),
        ]
      : null;
  }

  const { isLoading, userList, activeUserId } = useApprovalTime(
    filters,
    isInfoApprovalLoaded,
    projectFilterByParam,
    isLoadingUserInfo
  );

  let userListToShow: UserToApprove[] | null = userList;

  const { trackPageView } = useMatomo();

  // call endpoint to have page filters
  const fetchInfoApprovalTime = async () => {
    let costCentersApprovalTime = [];
    let approvalTimeData;
    await TimesheetsService.getApprovalTimeFilters().then((response) => {
      approvalTimeData = response.data;
    });

    if (approvalTimeData?.data.costCenters) {
      addElementToArray(
        costCentersApprovalTime,
        approvalTimeData.data.costCenters
      );
      dispatch(
        setCostCentersApprovalTimeComplete(approvalTimeData.data.costCenters)
      );
    }

    return {
      costCentersApprovalTime: costCentersApprovalTime || null,
      projectsApprovalTime:
        approvalTimeData?.data?.projects.map((elem: any) => ({
          value: elem.entityId,
          label: elem.entityId + " - " + elem.entityName,
          categoryId: elem.categoryId,
          costCenterId: elem.costCenterId,
        })) || null,
      managersApprovalTime:
        approvalTimeData?.data?.managers.map((elem: ProjectManagerOrUser) => ({
          value: elem.id,
          label: elem.fullName,
          projectIds: elem.projectIds,
        })) || null,
    };
  };

  const setLocalFilters = (structureData) => {
    setFilterOptions({
      projectOwner:
        structureData.managersApprovalTime?.length === 1
          ? [structureData.managersApprovalTime[0]]
          : filters.projectOwner,
      costCenter: filters.costCenter,
      project:
        checkUrlParam(searchParams.get("projectId")) &&
        structureData.projectsApprovalTime
          ? [
              structureData.projectsApprovalTime.find(
                (elem) => elem.value === Number(searchParams.get("projectId"))
              ),
            ]
          : structureData.projectsApprovalTime?.length === 1
          ? [structureData.projectsApprovalTime[0]]
          : filters.project,
      billableType: filters.billableType,
      endDate: filters.endDate,
    });
  };

  useEffect(() => {
    dispatch(setActiveTab(ROUTE_APPROVE_TIME_TIMESHEETS));
    document.title = "The Hub - Approve Time";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    const loadInfoApproval = async () => {
      let metaInfoApproval: any = await fetchInfoApprovalTime();
      setLocalInfoApproval(metaInfoApproval);
      dispatch(setInfoApproval(metaInfoApproval));
      setProjectsApprovalTimeFiltered(metaInfoApproval.projectsApprovalTime);
      setLocalFilters(metaInfoApproval);
    };

    if (!isInfoApprovalLoaded) {
      loadInfoApproval();
    } else {
      setLocalFilters(localInfoApproval);
    }

    dispatch(
      changeOrder({
        orderBy: "userName",
        dataType: "string",
        criteria: "asc",
      })
    );

    return () => {
      // reset filters if user got to another page
      if (window.location.pathname !== ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER) {
        //  clean filters
        dispatch(resetFilters());
      }
    };
  }, [trackPageView]);

  const [entriesSelected, setEntriesSelected] = useState<UserToApprove[] | any>(
    []
  );
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(true);

  const isSelected = (entry: any) => {
    let response = entriesSelected.indexOf(entry);
    return response > -1;
  };

  const updatedSelected = (entry: UserToApprove) => {
    let response = entriesSelected.indexOf(entry);
    if (response < 0) {
      const dataUpdated = [entry, ...entriesSelected];
      setEntriesSelected(dataUpdated);
    } else {
      setEntriesSelected(
        entriesSelected.filter((item) => item.userId !== entry.userId)
      );
    }
  };

  const [filterOptions, setFilterOptions] = useState({
    projectOwner: filters.projectOwner,
    costCenter: filters.costCenter,
    project: filters.project,
    billableType: filters.billableType,
    endDate: filters.endDate,
  });

  const submit = () => {
    setAllSelected(false);
    setEntriesSelected([]);
    dispatch(setFilters(filterOptions));
  };

  const checkAll = (e: any) => {
    setAllSelected(e.target.checked);
    if (e.target.checked) {
      setEntriesSelected(
        userList?.filter((entry) => entry.userId !== activeUserId)
      );
    } else {
      setEntriesSelected([]);
    }
  };

  const changeToAprroved = (user: UserToApprove) => {
    // WO should not be approve, so we should correct update updated row
    return {
      ...user,
      status: APPROVED_TEXT,
      billableHours: 0,
      nonBillableHours: 0,
      internalHours: 0,
      billableIds: [],
      nonBillableIds: [],
      internalIds: [],
      pendingHours:
        user.pendingHours -
        (user.billableHours + user.nonBillableHours + user.internalHours),
    };
  };

  const approveTimeEntries = async () => {
    dispatch(setLoading(true));

    let existActualWO: boolean = false;

    let totalEntries: number[] = [];
    entriesSelected.forEach((elem) => {
      // just for added warning toaster
      if (elem.workOrderIds.length > 0) existActualWO = true;

      let partial = elem.billableIds
        .concat(elem.nonBillableIds)
        .concat(elem.internalIds);

      totalEntries = totalEntries.concat(partial);
      // should remove workOrdersIds...
      totalEntries = totalEntries.filter(
        (id) => !elem.workOrderIds.includes(id)
      );
    });

    let body = {
      timeEntriesIds: totalEntries,
      action: {
        id: 3,
        nonBillableReasonId: null,
        entryDate: null,
        entityId: null,
        categoryId: null,
      },
    };

    await TimesheetsService.updateTimeEntries(body)
      .then((response) => {
        dispatch(addToast({ mode: "success", message: response.data.message }));

        if (existActualWO) {
          dispatch(
            addToast({
              mode: "warning",
              message: "Time is approved when the Work Order is Closed",
            })
          );
        }

        // transform obejct to render
        userListToShow = !userListToShow
          ? null
          : userListToShow.map((user: UserToApprove) => {
              if (
                entriesSelected.includes(user) &&
                (user.billableHours ||
                  user.nonBillableHours ||
                  user.internalHours)
              ) {
                return changeToAprroved(user);
              } else {
                return user;
              }
            });
        dispatch(loadTimeEntriesInfo(userListToShow));

        // clean entries selected
        setEntriesSelected([]);
        setAllSelected(false);
        setSubmitEnabled(!checkNothingToApprove());
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  const checkNothingToApprove = () => {
    return !!(
      userListToShow &&
      !userListToShow.some((elem) => elem.status === "Pending")
    );
  };

  const handleChange = (value: any, field: string) => {
    setSubmitEnabled(true);

    let costCenterIds: any = [];
    let projectIds: any = [];
    let result: any = [];

    // ASUITE11-2698
    if (field === "costCenter" || field === "projectOwner") {
      if (field === "costCenter") {
        // for costCenter
        value.forEach((element) => {
          costCenterIds = costCenterIds.concat(
            getCostCenterChildrenIds(
              costCentersApprovalTimeComplete,
              element.value
            )
          );
        });
        // for projectOwner
        filterOptions.projectOwner?.forEach((element) => {
          projectIds = Array.from(
            new Set([...projectIds, ...element.projectIds])
          );
        });
      } else {
        value.forEach((element) => {
          projectIds = Array.from(
            new Set([...projectIds, ...element.projectIds])
          );
        });
        filterOptions.costCenter?.forEach((element) => {
          costCenterIds = costCenterIds.concat(
            getCostCenterChildrenIds(
              costCentersApprovalTimeComplete,
              element.value
            )
          );
        });
      }
      // no filters
      if (costCenterIds.length === 0 && projectIds.length === 0) {
        setProjectsApprovalTimeFiltered(projectsApprovalTime);
      } else if (costCenterIds.length === 0 && projectIds.length !== 0) {
        // filter by projectOwner
        setProjectsApprovalTimeFiltered(
          projectsApprovalTime.filter((project) =>
            projectIds.includes(project.value)
          )
        );
      } else if (costCenterIds.length !== 0 && projectIds.length === 0) {
        // filter by costCenter
        setProjectsApprovalTimeFiltered(
          projectsApprovalTime.filter((project) =>
            costCenterIds.includes(project.costCenterId)
          )
        );
      } else {
        // filter by costCenter and projectOwner
        result = projectsApprovalTime.filter((project) =>
          costCenterIds.includes(project.costCenterId)
        );
        setProjectsApprovalTimeFiltered(
          result.filter((project) => projectIds.includes(project.value))
        );
      }
      // clean projects filter
      setFilterOptions({
        ...filterOptions,
        [field]: value,
        project: [],
      });
    } else {
      setFilterOptions({
        ...filterOptions,
        [field]: value,
      });
    }
  };

  const handleClick = (user: UserToApprove, hoursType: string) => {
    let filterToSet = checkUrlParam(searchParams.get("projectId"))
      ? filterOptions
      : filters;
    dispatch(
      setSearchState({ user: user, filters: filterToSet, hoursType: hoursType })
    );
    navigate(ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER);
  };

  return (
    <>
      {(!isInfoApprovalLoaded || isLoadingUserInfo) && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {isInfoApprovalLoaded &&
        !isLoadingUserInfo &&
        !verifyModuleAccess(true, TIME_ADMIN_ACCESS, userRolesTimesModule) && (
          <ForbiddenPage />
        )}
      {isInfoApprovalLoaded &&
        !isLoadingUserInfo &&
        verifyModuleAccess(true, TIME_ADMIN_ACCESS, userRolesTimesModule) && (
          <div className="content">
            <div
              className="content-header w-100"
              style={{ margin: "0 0 20px" }}
            >
              <h2>Approve time</h2>
            </div>
            <div className="d-flex mb-4 justify-content-between">
              <div className="flex-column col-sm-2">
                <label className="col-form-label">
                  Project Owner
                  <Tooltip
                    title={
                      <b>
                        Time in projects that are assigned to this Project
                        Manager
                      </b>
                    }
                    placement="right"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <span>
                      <BiInfoCircle className="disc-icon ms-1" />
                    </span>
                  </Tooltip>
                </label>

                <Select
                  isMulti
                  placeholder="All"
                  options={managersApprovalTime}
                  value={filterOptions.projectOwner}
                  onChange={(projectOwner) =>
                    handleChange(projectOwner, "projectOwner")
                  }
                  isDisabled={isLoading}
                />
              </div>
              <div className="flex-column col-sm-2">
                <label className="col-form-label">
                  Cost Center
                  <Tooltip
                    title={
                      <b>
                        Time for users that are assigned to this Cost Center
                      </b>
                    }
                    placement="right"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <span>
                      <BiInfoCircle className="disc-icon ms-1" />
                    </span>
                  </Tooltip>
                </label>

                <Select
                  isMulti
                  placeholder="All Cost Centers"
                  options={costCentersApprovalTime}
                  value={filterOptions.costCenter}
                  onChange={(costCenter) =>
                    handleChange(costCenter, "costCenter")
                  }
                  isDisabled={isLoading}
                />
              </div>
              <div className="flex-column col-sm-2">
                <label className="col-form-label">Project</label>

                <Select
                  isMulti
                  placeholder="All"
                  options={projectsApprovalTimeFiltered}
                  value={filterOptions.project}
                  onChange={(project) => handleChange(project, "project")}
                  isDisabled={isLoading}
                />
              </div>
              <div className="flex-column col-sm-2">
                <label className="col-form-label">Billable/Non-billable</label>
                <Select
                  isMulti
                  placeholder="All"
                  options={hoursTypeOptions}
                  value={filterOptions.billableType}
                  onChange={(billableType) =>
                    handleChange(billableType, "billableType")
                  }
                  isDisabled={isLoading}
                />
              </div>
              <div className="flex-column col-sm-2">
                <label className="col-form-label">End date</label>
                <Calendar
                  className="pointer"
                  date={filterOptions.endDate}
                  onChange={(date: Date) => handleChange(date, "endDate")}
                  isDisabled={isLoading}
                />
              </div>
              <div className="d-flex flex-column" style={{ marginTop: "35px" }}>
                <button
                  className="btn btn-primary"
                  onClick={() => submit()}
                  disabled={!submitEnabled}
                >
                  Submit
                </button>
              </div>
            </div>

            <hr className="header-separator" />

            {isLoading && (
              <Spinner
                style={{
                  marginLeft: "45%",
                  marginTop: "10%",
                }}
              />
            )}

            {!isLoading && userListToShow && userListToShow.length > 0 && (
              <div className="d-flex mt-3 justify-content-end">
                <button
                  className="btn button-secondary"
                  onClick={() => approveTimeEntries()}
                  disabled={
                    entriesSelected.length === 0 ||
                    checkNothingToApprove() ||
                    entriesSelected.every(
                      (elem: UserToApprove) =>
                        !elem.billableHours &&
                        !elem.nonBillableHours &&
                        !elem.internalHours
                    )
                  }
                >
                  Approve
                </button>
              </div>
            )}

            {!isLoading && checkNothingToApprove() && (
              <div className="card-success mt-3">
                <div className="success-message mt-3">
                  <BiCheckCircle className="success-icon big ms-3" />
                  <span className="green-text ms-2">Nice Work!</span>
                  <span className="black-text ms-2">
                    There is no more time to approve here.
                  </span>
                </div>
              </div>
            )}

            {!isLoading &&
              (!userListToShow ||
                (userListToShow && userListToShow.length === 0)) && (
                <NoContent
                  title="No Results Found"
                  text="Try adjusting your query to find what you’re looking for."
                  conditionButton={false}
                  image={image}
                  style={{ margin: "3% auto" }}
                />
              )}

            {!isLoading && userListToShow && userListToShow.length > 0 && (
              <table className="grey-table-header-background table mt-3 mb-5">
                <thead className="align-middle sticky-header sticky">
                  <tr>
                    <th className="text-center border-dark border-top fw-500 py-1 column-5 small">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={allSelected}
                        disabled={false}
                        onChange={(e) => checkAll(e)}
                      />
                    </th>
                    <th
                      className={`border-dark border-top fw-500 py-2 ps-3 column-15 ${
                        sortBy.orderBy === "userName"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      key="userName"
                      id="UserName"
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "userName",
                            dataType: "string",
                          })
                        )
                      }
                    >
                      Name
                    </th>
                    <th
                      className={`text-center border-dark border-top border-right fw-500 py-2 pe-3 column-5 ${
                        sortBy.orderBy === "status"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      key="status"
                      id="Status"
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "status",
                            dataType: "string",
                          })
                        )
                      }
                    >
                      Status
                    </th>
                    <th
                      className={`border-dark border-top border-right fw-500 py-2 column-25 text-center ${
                        sortBy.orderBy === "startDate"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      key="startDate"
                      id="Dates"
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "startDate",
                            dataType: "string",
                          })
                        )
                      }
                    >
                      <div className="d-flex justify-content-evenly">
                        <span>Start Date</span>
                        <span> - </span>
                        <span>End Date</span>
                      </div>
                    </th>
                    <th
                      className="border-dark border-top border-right fw-500 py-2 column-20 text-center"
                      key="billableHours"
                      id="Hours worked"
                    >
                      <p>Hours by type</p>
                      <div className="d-flex justify-content-evenly">
                        <p
                          className={`helper-title me-1 ${
                            sortBy.orderBy === "billableHours"
                              ? "sorted-" + sortBy.criteria
                              : ""
                          }`}
                          onClick={() =>
                            dispatch(
                              changeOrder({
                                orderBy: "billableHours",
                                dataType: "number",
                              })
                            )
                          }
                        >
                          Client Billable
                        </p>
                        <p
                          className={`helper-title me-1 ${
                            sortBy.orderBy === "nonBillableHours"
                              ? "sorted-" + sortBy.criteria
                              : ""
                          }`}
                          onClick={() =>
                            dispatch(
                              changeOrder({
                                orderBy: "nonBillableHours",
                                dataType: "number",
                              })
                            )
                          }
                        >
                          Client Non-Billable
                        </p>
                        <p
                          className={`helper-title ${
                            sortBy.orderBy === "internalHours"
                              ? "sorted-" + sortBy.criteria
                              : ""
                          }`}
                          onClick={() =>
                            dispatch(
                              changeOrder({
                                orderBy: "internalHours",
                                dataType: "number",
                              })
                            )
                          }
                        >
                          Internal
                        </p>
                      </div>
                    </th>
                    <th
                      className={`border-dark border-top border-right fw-500 py-2 column-5 text-center ${
                        sortBy.orderBy === "workOrderHours"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "workOrderHours",
                            dataType: "number",
                          })
                        )
                      }
                      key="workOrderHours"
                      id="Work Order hours"
                    >
                      <div className="d-flex justify-content-center">
                        Work Orders{" "}
                        <Tooltip
                          title={
                            <b>
                              Time is approved when the Work Order is Closed
                            </b>
                          }
                          placement="top"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <span>
                            <BiInfoCircle className="disc-icon ms-1" />
                          </span>
                        </Tooltip>
                      </div>
                    </th>
                    <th
                      className="border-dark border-top fw-500 py-2 column-5 text-center px-0"
                      id="Total hours"
                      key="pendingHours"
                    >
                      <p>Total hours</p>
                      <div className="d-flex justify-content-evenly">
                        <p
                          className={`helper-title me-1 ${
                            sortBy.orderBy === "pendingHours"
                              ? "sorted-" + sortBy.criteria
                              : ""
                          }`}
                          onClick={() =>
                            dispatch(
                              changeOrder({
                                orderBy: "pendingHours",
                                dataType: "number",
                              })
                            )
                          }
                        >
                          Pending
                        </p>
                        <p
                          className={`helper-title ${
                            sortBy.orderBy === "totalHours"
                              ? "sorted-" + sortBy.criteria
                              : ""
                          }`}
                          onClick={() =>
                            dispatch(
                              changeOrder({
                                orderBy: "totalHours",
                                dataType: "number",
                              })
                            )
                          }
                        >
                          Total
                        </p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userListToShow &&
                    userListToShow.map((user: UserToApprove) => {
                      return (
                        <tr
                          key={user.userId}
                          style={{ verticalAlign: "middle" }}
                        >
                          <td className="text-center py-0 border-dark">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={isSelected(user)}
                              disabled={user.userId === activeUserId}
                              onChange={() => updatedSelected(user)}
                            />
                          </td>
                          <td className="py-3 ps-3 fw-500 border-dark">
                            <div className="td-text">{user.userName}</div>
                          </td>
                          <td className="py-3 text-center fw-500 border-dark border-right">
                            <div className={`td-status ${user.status} me-2`}>
                              {user.status}
                              {user.status === LOCKED && (
                                <BiLockAlt
                                  className="ms-1"
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                            </div>
                          </td>
                          <td className="py-3 ps-2 fw-500 border-dark border-right">
                            <div className="d-flex justify-content-evenly">
                              <div className="td-text">
                                {dateFormatEntryEdit(user.startDate)}
                              </div>
                              <div className="td-text px-2">-</div>
                              <div className="td-text">
                                {dateFormatEntryEdit(user.endDate)}
                              </div>
                            </div>
                          </td>
                          <td className="py-3 ps-2 fw-500 border-dark border-right">
                            <div className="d-flex justify-content-between text-end">
                              <div
                                className={`td-approval ${
                                  user.billableHours > 0
                                    ? "td-task-link-approval"
                                    : "td-text"
                                }`}
                                style={{ width: "33%" }}
                                onClick={() =>
                                  user.billableHours > 0
                                    ? handleClick(user, "billableHours")
                                    : null
                                }
                              >
                                {quantityFormat(user.billableHours)}
                              </div>
                              <div
                                className={`td-approval ${
                                  user.nonBillableHours > 0
                                    ? "td-task-link-approval"
                                    : "td-text"
                                }`}
                                style={{ width: "33%" }}
                                onClick={() =>
                                  user.nonBillableHours > 0
                                    ? handleClick(user, "nonBillableHours")
                                    : null
                                }
                              >
                                {quantityFormat(user.nonBillableHours)}
                              </div>
                              <div
                                className={`td-approval ${
                                  user.internalHours > 0
                                    ? "td-task-link-approval"
                                    : "td-text"
                                }`}
                                style={{ width: "33%" }}
                                onClick={() =>
                                  user.internalHours > 0
                                    ? handleClick(user, "internalHours")
                                    : null
                                }
                              >
                                {quantityFormat(user.internalHours)}
                              </div>
                            </div>
                          </td>
                          <td className="py-3 pe-3 fw-500 border-dark border-right text-center">
                            <div
                              className={`${
                                user.workOrderHours > 0
                                  ? "td-task-link-approval"
                                  : "td-text"
                              }`}
                              onClick={() =>
                                user.workOrderHours > 0
                                  ? handleClick(user, "workOrderHours")
                                  : null
                              }
                            >
                              {quantityFormat(user.workOrderHours)}
                            </div>
                          </td>
                          <td className="py-3 ps-3 fw-500 border-dark">
                            <div className="d-flex justify-content-between text-end">
                              <div
                                className={`px-3 ${
                                  user.pendingHours > 0
                                    ? "td-task-link-approval"
                                    : "td-text"
                                }`}
                                style={{ minWidth: "auto" }}
                                onClick={() =>
                                  user.pendingHours > 0
                                    ? handleClick(user, "pendingHours")
                                    : null
                                }
                              >
                                {quantityFormat(user.pendingHours)}
                              </div>
                              <div className="td-text pe-2">/</div>
                              <div className="td-text pe-2">
                                {quantityFormat(user.totalHours)}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            <Footer />
          </div>
        )}
    </>
  );
};
