import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimesheetsService } from "../../services/timesheets";
import { UserAssignees } from "../../../types";
import {
  NonBillableReason,
  PayPeriods,
  ProjectManagerOrUser,
  TaskType,
} from "../../types";
import { UsersService } from "../../../SharedModule/services/users";
import { addElementToArray } from "../../utils/timesUtils";

export const fetchMetaTimes = async (
  id: string,
  isTimeAdmin: boolean,
  isTimeViewer: boolean
) => {
  let userAssignees,
    payPeriods,
    taskTypesData,
    nonBillableReasonsData,
    users,
    timeConfirmationData,
    costCentersTimeConfirmation;
  if (isTimeAdmin || isTimeViewer) {
    [
      userAssignees,
      payPeriods,
      taskTypesData,
      nonBillableReasonsData,
      users,
      timeConfirmationData,
    ] = await Promise.all([
      UsersService.getUserAssignees(id),
      TimesheetsService.getPayPeriods(),
      TimesheetsService.getTaskTypes(),
      TimesheetsService.getNonBillableReasons(),
      UsersService.getUserList(),
      TimesheetsService.getTimeConfirmationFilters(),
    ]);

    costCentersTimeConfirmation = [];
    if (timeConfirmationData) {
      addElementToArray(
        costCentersTimeConfirmation,
        timeConfirmationData.costCenters
      );
    }
  } else {
    [userAssignees, payPeriods, taskTypesData, nonBillableReasonsData, users] =
      await Promise.all([
        UsersService.getUserAssignees(id),
        TimesheetsService.getPayPeriods(),
        TimesheetsService.getTaskTypes(),
        TimesheetsService.getNonBillableReasons(),
        UsersService.getUserList(),
      ]);
    costCentersTimeConfirmation = null;
  }

  return {
    userAssignees:
      userAssignees?.data.map((assignee: UserAssignees) => ({
        value: assignee.id,
        label: assignee.fullName,
        email: assignee.email,
      })) || [],
    payPeriods: payPeriods.data.data,
    taskTypes:
      taskTypesData?.data.map((task: TaskType) => ({
        value: task.taskTypeId,
        label: task.taskTypeName,
        category: task.category,
      })) || [],
    nonBillableReasons:
      nonBillableReasonsData?.data.map((elem: NonBillableReason) => ({
        value: elem.id,
        label: elem.name,
      })) || [],
    timeUsers:
      users.map((elem: ProjectManagerOrUser) => ({
        value: elem.id,
        label: elem.fullName,
        isActive: elem.isActive,
        email: elem.email,
      })) || null,
    costCentersTimeConfirmation: costCentersTimeConfirmation || null,
  };
};

export type MetaTimesState = {
  userAssignees: Array<any> | null;
  payPeriods: PayPeriods | null;
  taskTypes: Array<any> | null;
  nonBillableReasons: Array<any> | null;
  timeUsers: Array<any> | null;
  costCentersTimeConfirmation: Array<any> | null;
};

type SetItem = Partial<MetaTimesState>;

const initialState: MetaTimesState = {
  userAssignees: null,
  payPeriods: null,
  taskTypes: null,
  nonBillableReasons: null,
  timeUsers: null,
  costCentersTimeConfirmation: null,
};

export const metaSlice = createSlice({
  name: "metaTimes",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    setMetaTimes: (state: MetaTimesState, action: PayloadAction<SetItem>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setMetaTimes } = metaSlice.actions;

// Selectors
export const selectUserAssignees = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.userAssignees;

export const selectPayPeriods = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.payPeriods;

export const selectTaskTypes = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.taskTypes;

export const selectNonBillableReasons = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.nonBillableReasons;

export const selectTimeUsers = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.timeUsers;

export const selectCostCentersTimeConfirmation = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.costCentersTimeConfirmation;

export const selectMetaTimesData = (state: { metaTimes: MetaTimesState }) => {
  return {
    userAssignees: selectUserAssignees(state),
    payPeriods: selectPayPeriods(state),
    taskTypes: selectTaskTypes(state),
    nonBillableReasons: selectNonBillableReasons(state),
    timeUsers: selectTimeUsers(state),
    costCentersTimeConfirmation: selectCostCentersTimeConfirmation(state),
  };
};

export default metaSlice.reducer;
