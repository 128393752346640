import { useEffect, useState, useRef, useCallback } from "react";
import { ModalLayout } from "../components/ModalLayout";
import { ModalPortal } from "../components/ModalPortal";
import { useDispatch, useSelector } from "react-redux";
import { SecurityService } from "../../SharedModule/services/security";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  login,
  logout,
  selectAuth,
  setUserRoles,
} from "../redux/reducers/auth.reducer";
import eventHandler from "../utils/eventHandler";
import { ROUTE_LOG_OUT } from "../utils/constants";

const eventTypes = [
  "keypress",
  "mousemove",
  "mousedown",
  "scroll",
  "touchmove",
  "pointermove",
];
export const addEventListeners = (listener: any) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, false);
  });
};
export const removeEventListeners = (listener: any) => {
  if (listener) {
    eventTypes.forEach((type) => {
      window.removeEventListener(type, listener, false);
    });
  }
};

export const TimeoutLogic = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const [, setStatus] = useState({ loaded: false, auth: false });
  const auth = useSelector(selectAuth);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    if (modalRef.current) {
      (modalRef.current as any).show(true);
    }
  };
  const closeModal = () => {
    if (modalRef.current as any) {
      (modalRef.current as any).show(false);
    }
  };

  eventHandler.on("showModalLogOut", () => {
    if (!isModalOpen && window.location.pathname !== ROUTE_LOG_OUT) {
      setIsModalOpen(true);
      showModal();
    }
    if (auth.isAuthenticated) {
      dispatch(logout());
    }
  });

  useEffect(() => {
    const createTimeout = () =>
      setTimeout(() => {
        if (!isModalOpen && window.location.pathname !== ROUTE_LOG_OUT) {
          localStorage.setItem("isTimeOut", "true");
          eventHandler.dispatch("logout", {});
          showModal();
          if (!auth.isAuthenticated) {
            dispatch(logout());
          }
          setIsModalOpen(true);
        }
        // set time in miliseconds to force logout and show modal => 1800000
      }, 1800000);

    const listener = () => {
      if (!isModalOpen && window.location.pathname !== ROUTE_LOG_OUT) {
        clearTimeout(timer);
        timer = createTimeout();
      }
    };
    // init
    let timer = createTimeout();
    addEventListeners(listener);
    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timer);
    };
  }, [isModalOpen]);

  const getRoles = useCallback(async () => {
    const userRoles = await SecurityService.getUserRole();
    Array.isArray(userRoles) && dispatch(setUserRoles(userRoles));
    setStatus({
      loaded: true,
      auth: Array.isArray(userRoles) && userRoles.length !== 0,
    });
  }, [dispatch]);

  const loginAgain = () => {
    closeModal();
    localStorage.setItem("isTimeOut", "false");
    const accessTokenRequest = {
      scopes: ["api://894e8b20-1b64-479c-b4a6-6e4fd7e1757f/Billing.Access"],
      account: accounts[0] as AccountInfo,
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((response) => {
        dispatch(
          login({
            accessToken: response.accessToken,
            activeUserId: accounts[0].localAccountId,
            activeUserName: (accounts[0] as any).name,
            activeUserEmail: accounts[0].username,
          })
        );
        getRoles();
        eventHandler.dispatch("login", {});
      })
      // ASUITE11-2744: this line going to redirect number of times to get correct token
      .catch(() => {
        instance.acquireTokenRedirect(accessTokenRequest);
      });

    setIsModalOpen(false);
  };

  return (
    <ModalPortal ref={modalRef} zIndex={170}>
      <ModalLayout
        title="Your session has timed out"
        text="Click below to Log in again and go back to the page you were on. If we can't bring you back, you will be taken to the Home page."
        btnLabel="Log in again"
        btnAction={loginAgain}
        actionPlaceholder="notCloseOnClickOutside"
      />
    </ModalPortal>
  );
};
