import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import image from "./../../styles/legacy/404.png";
import { NoContent } from "../components/NoContent";
import { useDispatch } from "react-redux";
import { logout } from "../redux/reducers/auth.reducer";
import eventHandler from "../utils/eventHandler";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "../utils/constants";
import { forceCollapseLeftNavBarFixed } from "../redux/reducers/uiShared.reducer";

export const LogOutPage = () => {
  const { trackPageView } = useMatomo();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(forceCollapseLeftNavBarFixed());

  dispatch(logout());

  useEffect(() => {
    document.title = "The Hub - Log Out";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  const LogIn = async () => {
    eventHandler.dispatch("authLogin", {});
    navigate(ROUTE_HOME);
    localStorage.setItem("isTimeOut", "false");
  };

  return (
    <NoContent
      title="Logout Successful"
      text="You have been securely logged out of your account."
      action={() => LogIn()}
      image={image}
      style={{ margin: "3% auto 0 auto" }}
      textButton="Log in"
      conditionButton={true}
    />
  );
};
