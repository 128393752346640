import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimesheetsService } from "../services/timesheets";
import {
  GetSummaryByTask,
  GetSummaryByTaskType,
  GetSummaryByUser,
  IsLoading,
  QueryResultList,
  Summary,
  loadQueryResultsInfo,
  setLoading,
} from "../redux/reducers/queryResult.reducer";
import {
  hasSuperTimeAdminAccess,
  hasTimeAdminAccess,
  hasTimeViewerAccess,
  isSupervisorOrProjectOwner,
  preparedFilters,
} from "../utils/timesUtils";
import {
  Holidays,
  ProjectList,
  SelectProjectOrWorkOrder,
} from "../redux/reducers/times.reducer";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";

export const useQueryResults = (
  filters: any,
  isInfoQueryLoaded: boolean,
  isLoadingUserInfo: boolean
) => {
  const dispatch = useDispatch();

  const projectList = useSelector(ProjectList);
  const selectProjectOrWorkOrder = useSelector(SelectProjectOrWorkOrder);
  const holidays = useSelector(Holidays);

  const MetaTimesInfo = useSelector(selectMetaTimesData);

  let filtersForRequest;
  if (isInfoQueryLoaded && !isLoadingUserInfo) {
    filtersForRequest = preparedFilters(filters);
  }

  const summary = useSelector(Summary);
  const summaryByUser = useSelector(GetSummaryByUser);
  const summaryByTask = useSelector(GetSummaryByTask);
  const summaryByTaskType = useSelector(GetSummaryByTaskType);
  const queryResultList = useSelector(QueryResultList);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isSupervisorOrPO = isSupervisorOrProjectOwner(userRolesTimesModule);
  const isTimeViewer = hasTimeViewerAccess(userRolesTimesModule);

  const { activeUserId } = useSelector(selectUserRoles);

  const isLoading = useSelector(IsLoading);

  const loadQueryResultsPageInfo = useCallback(async () => {
    dispatch(setLoading(true));
    if (isInfoQueryLoaded && !isLoadingUserInfo) {
      const queryResults = await TimesheetsService.getQueryResults(
        filtersForRequest
      );
      dispatch(loadQueryResultsInfo(queryResults.data.data));
    }
  }, [dispatch, filters, isInfoQueryLoaded, isLoadingUserInfo]);

  useEffect(() => {
    loadQueryResultsPageInfo();
  }, [loadQueryResultsPageInfo]);

  return {
    isLoading,
    summary,
    summaryByUser,
    summaryByTask,
    summaryByTaskType,
    queryResultList,
    projectList,
    selectProjectOrWorkOrder,
    holidays,
    isTimeAdmin,
    isSuperTimeAdmin,
    isSupervisorOrPO,
    isTimeViewer,
    activeUserId,
    MetaTimesInfo,
    loadQueryResultsPageInfo,
  };
};
