import { createSlice } from "@reduxjs/toolkit";
import {
  FIRST_DAY_THIS_MONTH,
  getIsoFormatStringWithTimeZone,
  getLastWeekDay,
  TODAY_STRING,
  YESTERDAY_STRING,
} from "../../../SharedModule/utils/dateUtils";

type costCentersID = {
  label: string;
  level: number;
  value: number;
};

type TimeConfirmationState = {
  keepFilters: boolean;
  filters: {
    allCountries: boolean;
    startDate: string;
    endDate: string;
    costCentersIDs: Array<costCentersID> | null;
  };
};

const today = new Date();

const initialState: TimeConfirmationState = {
  keepFilters: false,
  filters: {
    allCountries: true,
    startDate: getIsoFormatStringWithTimeZone(FIRST_DAY_THIS_MONTH),
    endDate:
      // all 1st of month should set end date as today (no matter weekend)
      today.getDate() === 1
        ? TODAY_STRING
        : // all 2nd of month should set end date as yesterday (no matter weekend)
        today.getDate() === 2 ||
          // all 3rd of month when is monday, should set end date as yesterday (no matter weekend)
          (today.getDate() === 3 && today.getDay() === 1) ||
          (today.getDay() !== 0 && today.getDay() !== 1 && today.getDay() !== 6)
        ? YESTERDAY_STRING
        : // should get previous friday if yesterday is monday
          getIsoFormatStringWithTimeZone(getLastWeekDay(2, today)),
    costCentersIDs: null,
  },
};

export const timeConfirmationSlice = createSlice({
  name: "timeConfirmation",
  initialState,
  reducers: {
    keepFilters: (state, action) => {
      state.keepFilters = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilters, keepFilters } = timeConfirmationSlice.actions;

export const SelectFilters = (state: any) => {
  return state.timeConfirmation.keepFilters
    ? state.timeConfirmation.filters
    : initialState.filters;
};

export const GetFilters = (state: any) => {
  return state.timeConfirmation.filters;
};

export const SelectInitialStateFilters = () => {
  return initialState.filters;
};

export default timeConfirmationSlice.reducer;
