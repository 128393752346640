import { Calendar } from "../../SharedModule/components/Calendar";
import { InputNumberHours } from "../../SharedModule/components/InputNumberHours";
import { Select } from "../../SharedModule/components/Select";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import {
  EntryListView,
  TaskType,
  TimeEntryEditForm,
  TimeEntryForm,
  TimeEntryPostForm,
  TimeEntrySheetView,
} from "../types";
import {
  checkDataIsLoaded,
  concatProjectsWorkOrders,
  empty,
  emptyProjectWorkOrder,
  getCategoryId,
  getProjectWorkOrder,
  getTaskCategoryName,
  getValueFromSelect,
  hasSuperTimeAdminAccess,
  hasTimeAdminAccess,
  hasTimeViewerAccess,
  iconDeleteEnabled,
  isSupervisorOrProjectOwner,
  setTimeEntryTypeIdforRequest,
} from "../utils/timesUtils";
import {
  IsSubmitted,
  setLoading,
  updateEntry,
} from "../redux/reducers/times.reducer";
import { TimesheetsService } from "../services/timesheets";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  getDateFromISOFormat,
  getPreviousWorkableDay,
  isSameDay,
  isBetweenTwoDates,
  getLastWorkableDay,
  getIsoFormatStringWithTimeZone,
  getDateFromDateString,
} from "../../SharedModule/utils/dateUtils";
import { BiEditAlt, BiTrash, BiError, BiErrorCircle } from "react-icons/bi";
import { dateFormatEntryEdit, decodeURIComponentSafe } from "../../SharedModule/utils/formatters";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import {
  BAMBOO_HR,
  BENEFITS,
  billableIds,
  CHANGE_GEAR,
  CLIENT_PRICE_SCHEDULED_ERROR,
  CLOUDFARE_ERROR,
  CLOUDFARE_ERROR_MESSAGE,
  INTERNAL_PROJECT,
  NONE,
  PROJ_MEAL_BREAK_ID,
  PROJ_NURSING_MOTHER_ID,
  PROJ_PAID_TIME_OFF_ID,
  PROJ_UNPAID_TIME_OFF_ID,
  STATUS_ERROR_FORBIDDEN,
  TASKTYPE_HOLIDAY_ID,
  TASKTYPE_MEAL_BREAK_ID,
  TASKTYPE_NURSING_MOTHER_ID,
  TASKTYPE_PAID_TIME_OFF,
  TASKTYPE_UNPAID_TIME_OFF_ID,
  UNPAID_TIME_OFF,
} from "../../SharedModule/utils/constants";
import {
  alreadyExistMealBreak,
  getIndexFromMealBreakHours,
  mealBreakHours,
} from "../utils/mealBreakHoursUtils";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import { Link } from "react-router-dom";
import { UsersService } from "../../SharedModule/services/users";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export const EntryHoursBox = (props: any) => {
  const { trackEvent } = useMatomo();

  const MetaTimesInfo = useSelector(selectMetaTimesData);

  const today = new Date();

  const { activeUserId } = useSelector(selectUserRoles);

  // need this value to calculate error of 24hs total
  const initialEntryHours = props.editMode ? props.state.entryHours : 0;

  let actualProjectsWorkOrders = props.projectsWorkOrders;
  let partialDescription = "";

  // to find taskTypecategoryId and isInternal
  let concatedSelectProjectOrWorkOrder = concatProjectsWorkOrders(
    props.projectsWorkOrders
  );

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isSubmitted = useSelector(IsSubmitted);

  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isSupervisorOrPO = isSupervisorOrProjectOwner(userRolesTimesModule);
  const isTimeViewer = hasTimeViewerAccess(userRolesTimesModule);

  // ASUITE11-2418: Time Viewer Role - FE
  let disabledByTimeViewer =
    !isTimeAdmin && isTimeViewer && activeUserId !== props.userIdParam;

  // ASUITE11-2561: imported from changeGear or Bamboo and could not edit holidays
  const [
    isImportedFromChangeGearOrBamboo,
    setIsImportedFromChangeGearOrBamboo,
  ] = useState(
    props.activeEntry
      ? props.activeEntry.timeEntrySourceId === 3 ||
          (props.activeEntry.timeEntrySourceId === 2 &&
            props.activeEntry.taskTypeId !== TASKTYPE_HOLIDAY_ID) ||
          (props.activeEntry.timeEntrySourceId === 2 &&
            props.activeEntry.taskTypeId === TASKTYPE_HOLIDAY_ID &&
            !isSuperTimeAdmin) ||
          (props.activeEntry.timeEntrySourceId === 2 &&
            props.activeEntry.taskTypeId === TASKTYPE_HOLIDAY_ID &&
            isSuperTimeAdmin &&
            activeUserId === props.userIdParam)
      : false
  );
  const [
    isImportedFromChangeGearOrBambooText,
    setIsImportedFromChangeGearOrBambooText,
  ] = useState(
    isImportedFromChangeGearOrBamboo &&
      props.activeEntry.timeEntrySourceId === 3
      ? CHANGE_GEAR
      : BAMBOO_HR
  );

  // ASUITE11-2736: Time Admin: Delete imported time (from bamboo whan task type is PTO or UTO) for others
  const [deletableFromBambooSuperAdmin, setDeletableFromBambooSuperAdmin] =
    useState(
      props.activeEntry
        ? props.activeEntry.timeEntrySourceId === 2 &&
            isSuperTimeAdmin &&
            props.activeEntry.taskTypeId !== TASKTYPE_HOLIDAY_ID
        : false
    );

  // In useState below, getTaskCategoryName was receive the entire element and not the value (taskTypesCategory)
  const getTaskTypesCategoryFromProjectWorkOrder = (value) => {
    let response = getProjectWorkOrder(concatedSelectProjectOrWorkOrder, value);
    return response?.taskTypesCategory;
  };

  const [actualTaskTypes, setActualTaskTypes] = useState<any>(
    props.state.entryProjectWorkOrder &&
      props.state.entryProjectWorkOrder.value !== 0
      ? MetaTimesInfo.taskTypes?.filter(
          (task: TaskType) =>
            task.category ===
            getTaskCategoryName(
              props.state.entryProjectWorkOrder.taskTypesCategory
                ? props.state.entryProjectWorkOrder.taskTypesCategory
                : getTaskTypesCategoryFromProjectWorkOrder(
                    props.state.entryProjectWorkOrder.value
                  )
            )
        )
      : []
  );

  const dispatch = useDispatch();

  let totalHoursActualDay = 0;

  const internalNonBillableReason = MetaTimesInfo.nonBillableReasons?.find(
    (elem) => elem.label === INTERNAL_PROJECT
  );
  const unpaidTimeOffNonBillableReason = MetaTimesInfo.nonBillableReasons?.find(
    (elem) => elem.label === UNPAID_TIME_OFF
  );
  const benefitsTimeOffNonBillableReason =
    MetaTimesInfo.nonBillableReasons?.find((elem) => elem.label === BENEFITS);

  const defaultTaskTypeNursingMother = getValueFromSelect(
    MetaTimesInfo.taskTypes,
    31
  );

  // ASUITE11-2373: remove Benefits(8), Budget Exceeded(10), Unpaid Time Off(9) and ITX Investment: Internal Project(4) from the Non-Billable Reason dropdown
  const nonBillableReasonsAfterFilter =
    MetaTimesInfo.nonBillableReasons?.filter(
      (reason) => ![8, 10, 9, 4].includes(reason.value)
    );

  // task types when project is nursing mother: meal break, nursing mother
  const taskTypeNursingMother = MetaTimesInfo.taskTypes?.filter(
    (task) => task.value === 31
  );

  const checkIsInternal = (value) => {
    let response = getProjectWorkOrder(concatedSelectProjectOrWorkOrder, value);
    return response?.isInternal;
  };

  const [isProjectWorkOrderInternal, setIsProjectWorkOrderInternal] = useState(
    props.state.entryProjectWorkOrder &&
      props.state.entryProjectWorkOrder.isInternal
      ? props.state.entryProjectWorkOrder.isInternal
      : props.state.entryProjectWorkOrder
      ? checkIsInternal(props.state.entryProjectWorkOrder.value)
      : false
  );

  const [isMealBreak, setIsMealBreak] = useState(
    props.state.entryProjectWorkOrder &&
      props.state.entryProjectWorkOrder.value === PROJ_MEAL_BREAK_ID
      ? true
      : false
  );

  let initialMealBreakHour =
    props.editMode && isMealBreak
      ? mealBreakHours[
          getIndexFromMealBreakHours(props.state.entryDescription, true)
        ]
      : mealBreakHours[48];
  let endMealBreakHour =
    props.editMode && isMealBreak
      ? mealBreakHours[
          getIndexFromMealBreakHours(props.state.entryDescription, false)
        ]
      : mealBreakHours[50];

  const [mealBreakState, setMealBreakState] = useState({
    start: initialMealBreakHour,
    end: endMealBreakHour,
  });

  const [previousMealBreakState, setPreviousMealBreakState] = useState(
    props.state.entryProjectWorkOrder &&
      props.state.entryProjectWorkOrder.value === PROJ_MEAL_BREAK_ID
      ? true
      : false
  );

  const [isNursingMother, setIsNursingMother] = useState(
    props.state.entryProjectWorkOrder &&
      props.state.entryProjectWorkOrder.value === PROJ_NURSING_MOTHER_ID
      ? true
      : false
  );

  // set task type options for each project
  const setOptionsTaskTypes = (option: number) => {
    setActualTaskTypes(
      MetaTimesInfo.taskTypes?.filter(
        (task: TaskType) => task.category === getTaskCategoryName(option)
      )
    );
  };

  let previousWorkableDay = getPreviousWorkableDay(1, props.holidays);
  let lastWorkableDay: any = getLastWorkableDay();

  const [actualSourceUrl, setActualSourceUrl] = useState(
    props.editMode && props.activeEntry ? props.activeEntry.sourceUrl : null
  );

  const loadPropsState = (incomingState: any) => {
    let newState: TimeEntryForm = {
      entryCanEdit: incomingState.entryCanEdit,
      entryCanDelete: incomingState.entryCanDelete,
      entryId: incomingState.entryId,
      entryDate: incomingState.entryDate,
      entryDateValid:
        incomingState.entryDateValid &&
        (isSuperTimeAdmin ||
          (isSupervisorOrPO && activeUserId !== props.userIdParam) ||
          (props.editMode
            ? new Date(incomingState.entryDate) < lastWorkableDay
            : previousWorkableDay <= new Date(incomingState.entryDate) &&
              new Date(incomingState.entryDate) < lastWorkableDay)),
      entryProjectWorkOrder: incomingState.entryProjectWorkOrder
        ? incomingState.entryProjectWorkOrder
        : props.partialInactiveEntryProjectWorkOrder
        ? props.partialInactiveEntryProjectWorkOrder
        : emptyProjectWorkOrder,
      entryProjectWorkOrderValid: incomingState.entryProjectWorkOrder
        ? incomingState.entryProjectWorkOrderValid
        : false,
      entryHours: incomingState.entryHours,
      entryHoursValid: incomingState.entryHoursValid,
      // if projectWorkOrder has default task type
      entryTaskType: incomingState.entryTaskType
        ? incomingState.entryTaskType
        : incomingState.entryProjectWorkOrder &&
          incomingState.entryProjectWorkOrder.defaultTaskTypeId
        ? getValueFromSelect(
            MetaTimesInfo.taskTypes,
            incomingState.entryProjectWorkOrder.defaultTaskTypeId
          )
        : empty,
      entryTaskTypeValid: incomingState.entryTaskTypeValid,
      entryDescription: incomingState.entryDescription,
      entryIsBillable: incomingState.entryIsBillable,
      // on edition, logic to disabled billable checkbox and set task type internal
      entryNonBillableReason:
        incomingState.entryProjectWorkOrder &&
        incomingState.entryProjectWorkOrder.isInternal &&
        incomingState.entryProjectWorkOrder.entityId !== 24056
          ? internalNonBillableReason
          : incomingState.entryNonBillableReason,
      entryNonBillableReasonValid: incomingState.entryNonBillableReasonValid,
      isEntryValid: incomingState.isEntryValid,
      isEntryTouched: incomingState.isEntryTouched,
    };

    // set time entry initial state when is nursing mother
    if (
      incomingState.entryProjectWorkOrder &&
      incomingState.entryProjectWorkOrder.value === PROJ_NURSING_MOTHER_ID
    ) {
      newState.entryIsBillable = false;
      newState.entryNonBillableReason = benefitsTimeOffNonBillableReason;
      newState.entryNonBillableReasonValid = true;
      newState.entryTaskType = defaultTaskTypeNursingMother;
      newState.entryTaskTypeValid = true;
    }

    // set time entry initial state when is meal break
    if (
      incomingState.entryProjectWorkOrder &&
      incomingState.entryProjectWorkOrder.value === PROJ_MEAL_BREAK_ID
    ) {
      newState.entryTaskTypeValid = true;
      newState.entryNonBillableReasonValid = true;
      newState.isEntryValid = true;
      if (!props.editMode) {
        newState.entryDescription = incomingState.entryDescription
          ? incomingState.entryDescription
          : "12:00pm to 12:30pm";
        newState.entryHours = incomingState.entryHours
          ? incomingState.entryHours
          : 0.5;
        newState.entryHoursValid = incomingState.entryHoursValid
          ? incomingState.entryHoursValid
          : true;
        if (props.isModal) {
          newState.isEntryTouched = true;
        }
      }
    }

    // set time entry initial state when is Paid time off
    if (
      incomingState.entryProjectWorkOrder &&
      incomingState.entryProjectWorkOrder.value === PROJ_PAID_TIME_OFF_ID
    ) {
      newState.entryIsBillable = false;
      newState.entryNonBillableReason = benefitsTimeOffNonBillableReason;
      newState.entryNonBillableReasonValid = true;
    }

    // set time entry initial state when is Unpaid time off
    if (
      incomingState.entryProjectWorkOrder &&
      incomingState.entryProjectWorkOrder.value === PROJ_UNPAID_TIME_OFF_ID
    ) {
      newState.entryIsBillable = false;
      newState.entryNonBillableReasonValid = true;
      newState.entryNonBillableReason =
        newState.entryTaskType.value === 31
          ? benefitsTimeOffNonBillableReason
          : unpaidTimeOffNonBillableReason;
    }

    return newState;
  };

  const [entryState, setEntryState] = useState(loadPropsState(props.state));

  const [isHolidayFromBamboo, setIsHolidayFromBamboo] = useState(
    props.isHolidayFromBamboo
  );

  const loadTotalHoursActualDay = (date: string) => {
    totalHoursActualDay = 0;
    props.timeEntriesList
      .filter((entry) =>
        isSameDay(entry.entryDate, getDateFromDateString(date))
      )
      .forEach((entry: EntryListView) => {
        totalHoursActualDay += entry.hours;
      });
  };
  loadTotalHoursActualDay(entryState.entryDate);

  const initialState: TimeEntryForm = {
    entryCanEdit: props.state.entryCanEdit,
    entryCanDelete: props.state.entryCanDelete,
    entryId: props.state.entryId,
    entryDate: props.state.entryDate,
    entryDateValid:
      isSuperTimeAdmin ||
      (isSupervisorOrPO && activeUserId !== props.userIdParam) ||
      (previousWorkableDay <= new Date(props.state.entryDate) &&
        new Date(props.state.entryDate) < lastWorkableDay),
    entryProjectWorkOrder: emptyProjectWorkOrder,
    entryProjectWorkOrderValid: false,
    entryHours: "",
    entryHoursValid: false,
    entryTaskType: empty,
    entryTaskTypeValid: false,
    entryDescription: "",
    entryIsBillable: true,
    entryNonBillableReason: empty,
    entryNonBillableReasonValid: false,
    isEntryValid: false,
    isEntryTouched: false,
  };

  // ASUITE11-2609: to avoid click enter on textArea
  const textAreaRef: any = useRef();

  const [scheduledHours, setScheduledHours] = useState(null);

  // call endpoint to have page filters
  const getUserScheduledHours = async () => {
    let response = await UsersService.getUserDetails(props.userIdParam);
    setScheduledHours(response.data.scheduledHours);
  };

  // max hours allowed by user is equals to scheduledHours
  const maxHoursAllowed: number =
    isHolidayFromBamboo && scheduledHours ? scheduledHours : 24;

  useEffect(() => {
    checkEntryValid();

    if (props.isHolidayFromBamboo && !scheduledHours) {
      getUserScheduledHours();
    }

    const handler = (event: any) => {
      if (
        event.keyCode === 13 &&
        document.activeElement !== textAreaRef.current &&
        entryState.isEntryTouched &&
        entryState.isEntryValid
      ) {
        clickHandler();
      }
    };

    document.addEventListener("keydown", handler);

    return () => document.removeEventListener("keydown", handler);
  }, [entryState]);

  const checkEntryValid = () => {
    // edit entry on page times, not modal => update redux state
    if (!props.isModal) {
      dispatch(updateEntry({ entryState }));
    }

    if (entryState.isEntryValid && !props.isModal) {
      props.isTouched();
    }
  };

  const checkHoursValid = (value: any, project: any, task: any) => {
    let isMealBreakFromProject =
      project &&
      project.value === PROJ_UNPAID_TIME_OFF_ID &&
      task &&
      task.value === TASKTYPE_MEAL_BREAK_ID;
    return isMealBreak || isMealBreakFromProject
      ? Number(value) > 0 && Number(value) < 1.25
      : Number(value) > 0 &&
          Number(value) < 24 &&
          Number(value) % 0.25 === 0 &&
          (props.editMode
            ? Number(value) + totalHoursActualDay - initialEntryHours <= 24
            : Number(value) + totalHoursActualDay <= 24);
  };

  const checkStatus = (
    property: string,
    isFieldValid: boolean,
    shouldEvalHours: boolean | null
  ) => {
    switch (property) {
      case "entryProjectWorkOrder":
        return (
          entryState.entryDateValid &&
          isFieldValid &&
          (shouldEvalHours ? shouldEvalHours : entryState.entryHoursValid) &&
          entryState.entryTaskTypeValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryDate":
        // once date is changed, need to review with totals hours of day + actual entry hours < 24
        let isHoursFieldValid = checkHoursValid(
          entryState.entryHours,
          entryState.entryProjectWorkOrder,
          entryState.entryTaskType
        );
        return (
          isFieldValid &&
          entryState.entryProjectWorkOrderValid &&
          isHoursFieldValid &&
          entryState.entryTaskTypeValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryHours":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          isFieldValid &&
          entryState.entryTaskTypeValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryTaskType":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          (shouldEvalHours ? shouldEvalHours : entryState.entryHoursValid) &&
          isFieldValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryDescription":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          entryState.entryHoursValid &&
          isFieldValid &&
          entryState.entryTaskTypeValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryNonBillableReason":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          entryState.entryHoursValid &&
          entryState.entryTaskTypeValid &&
          (entryState.entryIsBillable || isFieldValid)
        );
      case "entryIsBillable":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          entryState.entryHoursValid &&
          entryState.entryTaskTypeValid &&
          (isFieldValid ||
            (entryState.entryNonBillableReason.label !== empty.label &&
              entryState.entryNonBillableReason.value !== empty.value))
        );
      default:
        break;
    }
  };

  const handleChange = (value, property) => {
    let isFieldValid;
    let actualEntryValid;
    let actualhoursEntryValid = false;
    let firstTimeMealBreakHoursValid;
    let mealBreakNewHours;

    // 2507 - meal break or nursing older than today for superadmin/supervisor/PO for other
    let checkFutureMealOrNursing =
      !isBetweenTwoDates(entryState.entryDate, null, today) &&
      isMealBreakOrNursing() &&
      ((isSuperTimeAdmin && activeUserId !== props.userIdParam) ||
        (isSupervisorOrPO && activeUserId !== props.userIdParam));

    switch (property) {
      case "entryProjectWorkOrder":
        isFieldValid =
          value.label !== emptyProjectWorkOrder.label &&
          value.value !== emptyProjectWorkOrder.value;

        setOptionsTaskTypes(value.taskTypesCategory);

        if (value.isInternal) {
          setIsProjectWorkOrderInternal(true);
        } else {
          setIsProjectWorkOrderInternal(false);
        }

        if (value.value === PROJ_MEAL_BREAK_ID) {
          setIsMealBreak(true);
          setPreviousMealBreakState(true);
          setIsNursingMother(false);

          mealBreakNewHours = updateMealBreakHours(
            mealBreakHours[48],
            "start",
            false
          );
          firstTimeMealBreakHoursValid = true;
          actualhoursEntryValid = true;

          let existMealBreak: any = props.timeEntriesList
            .filter((elem) => elem.entityId === PROJ_MEAL_BREAK_ID)
            .find((elem) =>
              isSameDay(elem.entryDate, new Date(entryState.entryDate))
            );

          // 2507 - meal break or nursing older than today for superadmin/supervisor/PO for other
          let checkFutureMealBreak =
            !isBetweenTwoDates(entryState.entryDate, null, today) &&
            ((isSuperTimeAdmin && activeUserId !== props.userIdParam) ||
              (isSupervisorOrPO && activeUserId !== props.userIdParam));

          // check status if after change defaultTaskTypeId has value
          actualEntryValid =
            isFieldValid &&
            entryState.entryDateValid &&
            !existMealBreak &&
            !checkFutureMealBreak &&
            firstTimeMealBreakHoursValid
              ? firstTimeMealBreakHoursValid
              : entryState.entryHoursValid;

          setEntryState({
            ...entryState,
            [property]: { value: value.value, label: value.label },
            entryProjectWorkOrderValid: isFieldValid,
            entryIsBillable: false,
            entryHours: mealBreakNewHours
              ? mealBreakNewHours
              : entryState.entryHours,
            entryHoursValid: actualhoursEntryValid
              ? actualhoursEntryValid
              : entryState.entryHoursValid,
            entryNonBillableReason: unpaidTimeOffNonBillableReason,
            entryNonBillableReasonValid: true,
            entryTaskType: !!value.defaultTaskTypeId
              ? getValueFromSelect(
                  MetaTimesInfo.taskTypes,
                  value.defaultTaskTypeId
                )
              : empty,
            entryTaskTypeValid: !!value.defaultTaskTypeId,
            entryDescription: partialDescription,
            isEntryTouched: true,
            isEntryValid: actualEntryValid,
          });
        } else {
          setIsMealBreak(false);
          setPreviousMealBreakState(false);

          // Should eval hours after change project, for example is has a default task type
          actualhoursEntryValid = checkHoursValid(
            entryState.entryHours,
            value,
            entryState.entryTaskType
          );

          if (value.value === PROJ_NURSING_MOTHER_ID) {
            setIsNursingMother(true);

            // 2507 - meal break or nursing older than today for superadmin/supervisor/PO for other
            let checkFutureNursing =
              !isBetweenTwoDates(entryState.entryDate, null, today) &&
              ((isSuperTimeAdmin && activeUserId !== props.userIdParam) ||
                (isSupervisorOrPO && activeUserId !== props.userIdParam));

            // check status if after change defaultTaskTypeId has value, except nursing mother that has one value for task type
            actualEntryValid = previousMealBreakState
              ? false
              : entryState.entryDateValid &&
                isFieldValid &&
                actualhoursEntryValid &&
                !checkFutureNursing;
          } else {
            setIsNursingMother(false);
            // check status if after change defaultTaskTypeId has value
            actualEntryValid = previousMealBreakState
              ? false
              : checkStatus(property, isFieldValid, actualhoursEntryValid) &&
                !!value.defaultTaskTypeId;
          }

          setEntryState({
            ...entryState,
            entryProjectWorkOrderValid: isFieldValid,
            isEntryTouched: true,
            isEntryValid: actualEntryValid,
            [property]: { value: value.value, label: value.label },
            entryIsBillable: value.isInternal ? false : true,
            entryNonBillableReason:
              value.value === PROJ_NURSING_MOTHER_ID
                ? benefitsTimeOffNonBillableReason
                : value.entityId === PROJ_PAID_TIME_OFF_ID ||
                  value.entityId === PROJ_UNPAID_TIME_OFF_ID
                ? getValueFromSelect(
                    MetaTimesInfo.nonBillableReasons,
                    value.entityId === PROJ_PAID_TIME_OFF_ID ? 8 : 9
                  )
                : value.isInternal
                ? internalNonBillableReason
                : empty,
            entryNonBillableReasonValid: value.isInternal,
            entryTaskType:
              value.value === PROJ_NURSING_MOTHER_ID
                ? defaultTaskTypeNursingMother
                : !!value.defaultTaskTypeId
                ? getValueFromSelect(
                    MetaTimesInfo.taskTypes,
                    value.defaultTaskTypeId
                  )
                : empty,
            entryTaskTypeValid:
              value.value === PROJ_NURSING_MOTHER_ID
                ? true
                : !!value.defaultTaskTypeId,
            entryDescription: previousMealBreakState
              ? ""
              : entryState.entryDescription,
            entryHours: previousMealBreakState ? "" : entryState.entryHours,
            entryHoursValid: previousMealBreakState
              ? false
              : actualhoursEntryValid,
          });
        }
        break;
      case "entryDate":
        let existMealBreak: any = isMealBreak
          ? props.timeEntriesList
              .filter((elem) => elem.entityId === PROJ_MEAL_BREAK_ID)
              .find((elem) => isSameDay(elem.entryDate, new Date(value)))
          : false;

        // recalculate sum of totals hours of all entries
        loadTotalHoursActualDay(value);

        isFieldValid =
          isSuperTimeAdmin ||
          (isSupervisorOrPO && activeUserId !== props.userIdParam)
            ? true
            : previousWorkableDay <= new Date(value) &&
              new Date(value) < lastWorkableDay;

        // 2507 - meal break or nursing older than today for superadmin/supervisor/PO for other
        let checkFutureMealOrNursingOnDate =
          !isBetweenTwoDates(value, null, today) &&
          isMealBreakOrNursing() &&
          ((isSuperTimeAdmin && activeUserId !== props.userIdParam) ||
            (isSupervisorOrPO && activeUserId !== props.userIdParam));

        actualEntryValid =
          checkStatus(property, isFieldValid, null) &&
          !existMealBreak &&
          !checkFutureMealOrNursingOnDate;

        actualhoursEntryValid = checkHoursValid(
          entryState.entryHours,
          entryState.entryProjectWorkOrder,
          entryState.entryTaskType
        );
        setEntryState({
          ...entryState,
          [property]: value,
          entryDateValid: isFieldValid,
          entryHoursValid: actualhoursEntryValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      case "entryHours":
        isFieldValid = checkHoursValid(
          value,
          entryState.entryProjectWorkOrder,
          entryState.entryTaskType
        );
        actualEntryValid =
          checkStatus(property, isFieldValid, null) &&
          !checkFutureMealOrNursing;
        setEntryState({
          ...entryState,
          [property]: value,
          entryHoursValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
          entryDescription: isMealBreak
            ? partialDescription
            : entryState.entryDescription,
        });
        break;
      case "entryTaskType":
        isFieldValid =
          value.label !== empty.label && value.value !== empty.value;

        // Nursing Mother: 31, Unpaid Time Off: 42, Meal Break: 30
        let partialEntryNonBillableReason =
          value.value === TASKTYPE_NURSING_MOTHER_ID
            ? benefitsTimeOffNonBillableReason
            : value.value === TASKTYPE_UNPAID_TIME_OFF_ID ||
              value.value === TASKTYPE_MEAL_BREAK_ID
            ? unpaidTimeOffNonBillableReason
            : null;

        // if change to meal break, should add hours validation
        actualhoursEntryValid = checkHoursValid(
          entryState.entryHours,
          entryState.entryProjectWorkOrder,
          value
        );

        // 2507 - meal break or nursing older than today for superadmin/supervisor/PO for other
        let checkMealOrNursing =
          !isBetweenTwoDates(entryState.entryDate, null, today) &&
          (value.value === TASKTYPE_NURSING_MOTHER_ID ||
            value.value === TASKTYPE_MEAL_BREAK_ID) &&
          ((isSuperTimeAdmin && activeUserId !== props.userIdParam) ||
            (isSupervisorOrPO && activeUserId !== props.userIdParam));

        actualEntryValid =
          checkStatus(property, isFieldValid, actualhoursEntryValid) &&
          !checkMealOrNursing;

        setEntryState({
          ...entryState,
          [property]: value,
          entryTaskTypeValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid:
            value.value === TASKTYPE_MEAL_BREAK_ID
              ? actualEntryValid && actualhoursEntryValid
              : actualEntryValid,
          entryNonBillableReason: partialEntryNonBillableReason
            ? partialEntryNonBillableReason
            : entryState.entryNonBillableReason,
          entryNonBillableReasonValid: partialEntryNonBillableReason
            ? true
            : entryState.entryNonBillableReason &&
              entryState.entryNonBillableReason.label !== empty.label &&
              entryState.entryNonBillableReason.value !== empty.value,
          entryHoursValid:
            value.value === TASKTYPE_MEAL_BREAK_ID
              ? actualhoursEntryValid
              : entryState.entryHoursValid,
        });
        break;
      case "entryDescription":
        isFieldValid = true;
        actualEntryValid =
          checkStatus(property, isFieldValid, null) &&
          !checkFutureMealOrNursing;
        setEntryState({
          ...entryState,
          [property]: value,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      case "entryNonBillableReason":
        isFieldValid =
          entryState.entryIsBillable ||
          (value.label !== empty.label && value.value !== empty.value);
        actualEntryValid =
          checkStatus(property, isFieldValid, null) &&
          !checkFutureMealOrNursing;
        setEntryState({
          ...entryState,
          [property]: value,
          entryNonBillableReasonValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      case "entryIsBillable":
        actualEntryValid =
          checkStatus(property, value, null) && !checkFutureMealOrNursing;
        setEntryState({
          ...entryState,
          [property]: value,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
          entryNonBillableReasonValid: value
            ? value
            : entryState.entryNonBillableReason.label !== empty.label &&
              entryState.entryNonBillableReason.value !== empty.value,
        });
        break;
      default:
        break;
    }
  };

  const saveAction = async () => {
    let request: TimeEntryPostForm[] = [];
    let bodyEdit: TimeEntryEditForm;
    let avoidResetPage: boolean = false;

    if (props.editMode) {
      // services to edit
      bodyEdit = {
        entryDate: entryState.entryDate,
        hours: entryState.entryHours,
        taskTypeId: entryState.entryTaskType.value,
        description: isMealBreak
          ? mealBreakState.start && mealBreakState.end
            ? mealBreakState.start.value + " to " + mealBreakState.end.value
            : entryState.entryDescription
          : entryState.entryDescription.length === 0
          ? NONE
          : encodeURIComponent(entryState.entryDescription),
        entityId: entryState.entryProjectWorkOrder.value,
        timeEntryCategoryId: getCategoryId(
          concatProjectsWorkOrders(actualProjectsWorkOrders),
          entryState.entryProjectWorkOrder.value
        ),
        timeEntryTypeId: setTimeEntryTypeIdforRequest(entryState),
        // could be non billable from BE, so sometimes entryNonBillableReason still empty on entry nonBillable
        nonBillableReasonId: entryState.entryIsBillable
          ? null
          : entryState.entryNonBillableReason.value
          ? entryState.entryNonBillableReason.value
          : null,
      };

      // ASUITE11-2618
      trackEvent({
        category: `${props.isListView ? "List View" : "Sheet View"}`,
        action: `Edit from ${props.isListView ? "List View" : "Sheet View"}`,
        documentTitle: document.location.hostname + "/" + document.title,
      });

      props.btnCancel();
      dispatch(setLoading(true));
      await TimesheetsService.editTimesEntry(bodyEdit, entryState.entryId)
        .then((response) => {
          dispatch(
            addToast({ mode: "success", message: response.data.message })
          );
          if (!props.isQueryPage) {
            props.resetPage();
          }
          props.reloadPage(entryState.entryDate);
        })
        .catch((err) => {
          if (
            err.response.data.error.startsWith(CLIENT_PRICE_SCHEDULED_ERROR)
          ) {
            dispatch(
              addToast({
                mode: "error",
                message: "Time Entry was not saved correctly",
              })
            );
            props.showClientPriceLevelError();
          } else {
            dispatch(
              addToast({
                mode: "error",
                message:
                  err.response.status === STATUS_ERROR_FORBIDDEN &&
                  err.response.data.includes(CLOUDFARE_ERROR)
                    ? CLOUDFARE_ERROR_MESSAGE
                    : err.response.data.error ||
                      "An error ocurred trying to perform operation",
              })
            );
          }
          dispatch(setLoading(false));
        });
    } else {
      // replicates services to add in time pages
      request.push({
        userId: props.userIdParam,
        entryDate: entryState.entryDate,
        hours: Number(entryState.entryHours),
        taskTypeId: entryState.entryTaskType.value,
        description: isMealBreak
          ? mealBreakState.start.value + " to " + mealBreakState.end.value
          : entryState.entryDescription.length === 0
          ? NONE
          : encodeURIComponent(entryState.entryDescription),
        entityId: entryState.entryProjectWorkOrder.value,
        timeEntryCategoryId: getCategoryId(
          concatProjectsWorkOrders(actualProjectsWorkOrders),
          entryState.entryProjectWorkOrder.value
        ),
        timeEntryTypeId: setTimeEntryTypeIdforRequest(entryState),
        timeEntrySourceId: 1,
        nonBillableReasonId: entryState.entryIsBillable
          ? null
          : entryState.entryNonBillableReason.value,
      });

      // ASUITE11-2618
      trackEvent({
        category: `${props.isListView ? "List View" : "Sheet View"}`,
        action: `Add from ${props.isListView ? "List View" : "Sheet View"}`,
        documentTitle: document.location.hostname + "/" + document.title,
      });

      props.btnCancel();
      dispatch(setLoading(true));
      await TimesheetsService.addTimesEntries(request)
        .then((response) => {
          // Entry was not save correctly
          if (response.data.data && response.data.data.length > 0) {
            // ASUITE11-2700: check type of error
            if (
              response.data.data[0].error.startsWith(
                CLIENT_PRICE_SCHEDULED_ERROR
              )
            ) {
              dispatch(
                addToast({
                  mode: "error",
                  message: response.data.message,
                })
              );
              avoidResetPage = true;
              props.showClientPriceLevelError();
            } else {
              dispatch(
                addToast({
                  mode: "error",
                  message:
                    response.data.data[0].error ||
                    "Time Entry was not saved correctly",
                })
              );
            }
          } else {
            dispatch(
              addToast({ mode: "success", message: response.data.message })
            );
          }
          if (!props.isQueryPage && !avoidResetPage) {
            props.resetPage();
          }
          if (!avoidResetPage) {
            props.reloadPage(entryState.entryDate);
          } else {
            dispatch(setLoading(false));
          }
        })
        .catch((err) => {
          dispatch(
            addToast({
              mode: "error",
              message:
                err.response.status === STATUS_ERROR_FORBIDDEN &&
                err.response.data.includes(CLOUDFARE_ERROR)
                  ? CLOUDFARE_ERROR_MESSAGE
                  : err.response.data.error ||
                    "An error ocurred trying to perform operation",
            })
          );
          dispatch(setLoading(false));
        });
    }
  };

  // on click in percil when multiples time entries in a cell
  const updateInfoModal = (entry: TimeEntrySheetView) => {
    // ASUITE11-2561: imported from changeGear or Bamboo and could not edit holidays
    setIsImportedFromChangeGearOrBamboo(
      entry.timeEntrySourceId === 3 ||
        (entry.timeEntrySourceId === 2 &&
          entry.taskTypeId !== TASKTYPE_HOLIDAY_ID) ||
        (entry.timeEntrySourceId === 2 &&
          entry.taskTypeId === TASKTYPE_HOLIDAY_ID &&
          !isSuperTimeAdmin) ||
        (entry.timeEntrySourceId === 2 &&
          entry.taskTypeId === TASKTYPE_HOLIDAY_ID &&
          isSuperTimeAdmin &&
          activeUserId === props.userIdParam)
    );
    setIsImportedFromChangeGearOrBambooText(
      isImportedFromChangeGearOrBamboo && entry.timeEntrySourceId === 3
        ? CHANGE_GEAR
        : BAMBOO_HR
    );
    // ASUITE11-2736: Time Admin: Delete imported time (from bamboo whan task type is PTO or UTO) for others
    setDeletableFromBambooSuperAdmin(
      entry.timeEntrySourceId === 2 &&
        isSuperTimeAdmin &&
        entry.taskTypeId !== TASKTYPE_HOLIDAY_ID
    );

    // should check if holiday from bamboo when change time entry in multiple on a day
    setIsHolidayFromBamboo(
      entry.timeEntrySourceId === 2 &&
        entry.source === BAMBOO_HR &&
        entry.taskTypeId === TASKTYPE_HOLIDAY_ID
    );

    // is meal break
    if (entry.entityId === PROJ_MEAL_BREAK_ID) {
      setMealBreakState({
        start:
          mealBreakHours[getIndexFromMealBreakHours(entry.description, true)],
        end: mealBreakHours[
          getIndexFromMealBreakHours(entry.description, false)
        ],
      });
    }

    let actualProjectsWorkOrder = getProjectWorkOrder(
      concatProjectsWorkOrders(actualProjectsWorkOrders),
      entry.entityId
    );

    let newEditForm: TimeEntryForm = {
      // should combine entry canEdit with status pending (timeEntryStatusId === 1 or super admin and timeEntryStatusId === 2) to allow to edit
      entryCanEdit:
        entry.timeEntrySourceId === 3 || entry.timeEntryStatusId === 3 // imported from change gear or time entry status LOCKED
          ? false
          : (entry.canEdit && entry.timeEntryStatusId === 1) ||
            (entry.timeEntryStatusId === 2 && isSuperTimeAdmin),
      entryCanDelete:
        entry.timeEntrySourceId === 3 || entry.timeEntryStatusId === 3 // imported from change gear
          ? false
          : entry.canDelete,
      entryId: entry.timeEntryId,
      entryDate: getDateFromISOFormat(entry.entryDate),
      entryDateValid: true,
      entryProjectWorkOrder: actualProjectsWorkOrder
        ? actualProjectsWorkOrder
        : props.partialInactiveEntryProjectWorkOrder
        ? props.partialInactiveEntryProjectWorkOrder
        : emptyProjectWorkOrder,
      entryProjectWorkOrderValid: actualProjectsWorkOrder ? true : false,
      entryHours: entry.hours,
      entryHoursValid: true,
      entryTaskType: getValueFromSelect(
        MetaTimesInfo.taskTypes,
        entry.taskTypeId
      )
        ? getValueFromSelect(MetaTimesInfo.taskTypes, entry.taskTypeId)
        : {
            value: entry.taskTypeId,
            label: entry.taskType,
          },
      entryTaskTypeValid: true,
      entryDescription: entry.description,
      entryIsBillable: billableIds.includes(
        entry.timeEntryTypeId.toString().toLowerCase()
      ),
      entryNonBillableReason: billableIds.includes(
        entry.timeEntryTypeId.toString().toLowerCase()
      )
        ? empty
        : getValueFromSelect(
            MetaTimesInfo.nonBillableReasons,
            entry.nonBillableReasonId
          )
        ? getValueFromSelect(
            MetaTimesInfo.nonBillableReasons,
            entry.nonBillableReasonId
          )
        : {
            value: entry.nonBillableReasonId,
            label: entry.nonBillableReason,
          },
      entryNonBillableReasonValid: entry.nonBillableReasonId ? true : false,
      isEntryValid: false,
      isEntryTouched: false,
    };

    setActualSourceUrl(entry.sourceUrl);
    setEntryState(newEditForm);
  };

  // Sheet view save - modal (add/edit)
  const clickHandler = () => {
    // if entry is valid => save, if not modal still present
    if (entryState.isEntryValid) {
      saveAction();
    }
  };

  const handleClear = () => {
    setEntryState(initialState);
    setIsProjectWorkOrderInternal(false);
    setIsMealBreak(false);
    props.onClear(initialState.entryId);
  };

  const checkTotalHours = () => {
    if (props.editMode) {
      return (
        Number(entryState.entryHours) +
          totalHoursActualDay -
          initialEntryHours >
        24
      );
    } else {
      return Number(entryState.entryHours) + totalHoursActualDay > 24;
    }
  };

  const updateMealBreakHours = (
    value: any,
    type: string,
    shouldUpdateState: boolean
  ) => {
    let index = mealBreakHours.indexOf(value);
    let newHours = 0;
    if (type === "start") {
      newHours = 0.5;
      setMealBreakState({
        start: value,
        end: mealBreakHours[index + 2],
      });
    } else {
      let indexStart = mealBreakHours.indexOf(mealBreakState.start);
      newHours = (index - indexStart) * 0.25;
      setMealBreakState({
        ...mealBreakState,
        end: value,
      });
    }

    partialDescription =
      type === "start"
        ? value.value + " to " + mealBreakHours[index + 2].value
        : mealBreakState.start.value + " to " + value.value;

    if (shouldUpdateState) {
      handleChange(newHours, "entryHours");
    } else {
      return newHours;
    }
  };

  const isEntryTimeOffValid = (entry: TimeEntryForm) => {
    let isTimeoff =
      entry.entryProjectWorkOrder.value === PROJ_PAID_TIME_OFF_ID ||
      (entry.entryProjectWorkOrder.value === PROJ_UNPAID_TIME_OFF_ID &&
        entry.entryTaskType.value === TASKTYPE_UNPAID_TIME_OFF_ID);
    return (
      !isTimeoff ||
      (isTimeoff && activeUserId !== props.userIdParam && isSuperTimeAdmin)
    );
  };

  const isMealBreakOrNursing = () => {
    return (
      entryState.entryTaskType.value === TASKTYPE_MEAL_BREAK_ID ||
      entryState.entryTaskType.value === TASKTYPE_NURSING_MOTHER_ID ||
      entryState.entryProjectWorkOrder.value === PROJ_MEAL_BREAK_ID ||
      entryState.entryProjectWorkOrder.value === PROJ_NURSING_MOTHER_ID
    );
  };

  const setSaveDisabled = () => {
    let response =
      !entryState.isEntryValid ||
      !entryState.isEntryTouched ||
      // ASUITE11-2512: can't edit on inactive proyect/wo/task
      props.partialInactiveEntryProjectWorkOrder ||
      (props.editMode &&
        (isHolidayFromBamboo
          ? maxHoursAllowed < Number(entryState.entryHours)
          : !entryState.entryCanEdit)) ||
      (!props.editMode &&
        alreadyExistMealBreak(entryState, props.timeEntriesList)) ||
      !isEntryTimeOffValid(entryState) ||
      // unpaid time off (meal break and nursing) for supervisor/project owner
      (!isSuperTimeAdmin &&
        isMealBreakOrNursing() &&
        !isBetweenTwoDates(entryState.entryDate, previousWorkableDay, today)) ||
      // could not edit for common user or supervisor/project owner for himself outside current week
      (!isSuperTimeAdmin &&
        activeUserId === props.userIdParam &&
        (entryState.entryProjectWorkOrder.value !== PROJ_MEAL_BREAK_ID ||
          entryState.entryProjectWorkOrder.value !== PROJ_NURSING_MOTHER_ID ||
          entryState.entryProjectWorkOrder.value !== PROJ_PAID_TIME_OFF_ID ||
          entryState.entryProjectWorkOrder.value !== PROJ_UNPAID_TIME_OFF_ID) &&
        !isBetweenTwoDates(entryState.entryDate, null, lastWorkableDay)) ||
      // 2507 - meal break or nursing older than today for superadmin/supervisor/PO for other
      (!isBetweenTwoDates(entryState.entryDate, null, today) &&
        isMealBreakOrNursing() &&
        ((isSuperTimeAdmin && activeUserId !== props.userIdParam) ||
          (isSupervisorOrPO && activeUserId !== props.userIdParam))) ||
      // ASUITE11-2561
      isImportedFromChangeGearOrBamboo ||
      // ASUITE11-2418: Time Viewer Role - FE
      disabledByTimeViewer;
    return response;
  };

  return (
    <form
      className={`card entry-hour-box-background px-4 py-2 ${
        !props.isModal ? "my-3" : ""
      }`}
    >
      {props.title && (
        <div className="row">
          <h4>{props.title}</h4>
        </div>
      )}
      {props.multipleTimeEntries && (
        <>
          <div className="row">
            <div className="d-flex">
              <div className="border-dark fw-500 ps-2 column-10">Date</div>
              <div className="border-dark fw-500 ps-2 column-10">Time</div>
              <div className="border-dark fw-500 ps-2 column-15">Type</div>
              <div className="border-dark fw-500 ps-2 column-55">
                Description
              </div>
              <div className="border-dark fw-500 text-center py-0 ps-2 column-10"></div>
            </div>
          </div>
          {props.multipleTimeEntries.map((entry: TimeEntrySheetView) => {
            return (
              <div className="row mt-1" key={entry.timeEntryId}>
                <div className="d-flex table-row-multiple-entries">
                  <div className="column-10 fw-500 py-1 ps-2">
                    {dateFormatEntryEdit(entry.entryDate)}
                  </div>
                  <div className="column-10 fw-500 py-1 ps-2">
                    {`${entry.hours.toFixed(2)} hours`}
                  </div>
                  <div className="column-15 fw-500 py-1 ps-2">
                    {getValueFromSelect(
                      MetaTimesInfo.taskTypes,
                      entry.taskTypeId
                    )
                      ? getValueFromSelect(
                          MetaTimesInfo.taskTypes,
                          entry.taskTypeId
                        ).label
                      : entry.taskType}
                  </div>
                  <div className="column-55 fw-500 py-1 ps-2">
                    <div className="entry-description">{decodeURIComponentSafe(entry.description)}</div>
                  </div>
                  <div className="d-flex column-10 justify-content-center py-1 ps-2">
                    <BiEditAlt
                      className="icon-entry-action me-2"
                      onClick={() => updateInfoModal(entry)}
                    />
                    <BiTrash
                      className={`icon-entry-action ${
                        iconDeleteEnabled(
                          entry,
                          activeUserId,
                          props.userIdParam,
                          isSuperTimeAdmin,
                          isSupervisorOrPO,
                          isTimeViewer,
                          previousWorkableDay,
                          today,
                          lastWorkableDay
                        ) &&
                        !props.partialInactiveEntryProjectWorkOrder &&
                        !disabledByTimeViewer
                          ? ""
                          : "disabled"
                      }`}
                      onClick={() =>
                        iconDeleteEnabled(
                          entry,
                          activeUserId,
                          props.userIdParam,
                          isSuperTimeAdmin,
                          isSupervisorOrPO,
                          isTimeViewer,
                          previousWorkableDay,
                          today,
                          lastWorkableDay
                        ) &&
                        !props.partialInactiveEntryProjectWorkOrder &&
                        !disabledByTimeViewer
                          ? props.deleteEntry(entry)
                          : undefined
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}

      <div className={`row ${props.isModal ? "mt-3" : ""}`}>
        <div
          className="d-flex flex-column col-sm-3"
          style={{ maxWidth: "220px" }}
        >
          <label className="col-form-label">Date</label>
          <Calendar
            className={`me-3 ${
              entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryDateValid
                ? "border-error"
                : ""
            }`}
            date={entryState.entryDate}
            onChange={(value) => handleChange(value, "entryDate")}
            minDate={
              isSuperTimeAdmin ||
              (isSupervisorOrPO && activeUserId !== props.userIdParam)
                ? undefined
                : previousWorkableDay
            }
            maxDate={
              isSuperTimeAdmin ||
              (isSupervisorOrPO && activeUserId !== props.userIdParam)
                ? undefined
                : lastWorkableDay
            }
            isDisabled={
              !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin, isTimeViewer) ||
              (props.editMode &&
                (!isTimeAdmin ||
                  (isSupervisorOrPO &&
                    activeUserId === props.userIdParam &&
                    !isSuperTimeAdmin)) &&
                getDateFromISOFormat(
                  getIsoFormatStringWithTimeZone(previousWorkableDay)
                ) >
                  getDateFromISOFormat(
                    getIsoFormatStringWithTimeZone(
                      new Date(props.state.entryDate)
                    )
                  )) ||
              isImportedFromChangeGearOrBamboo ||
              props.partialInactiveEntryProjectWorkOrder ||
              props.addMealBreakFromListView ||
              !entryState.entryCanEdit ||
              disabledByTimeViewer
            }
          />
          {!entryState.entryDateValid &&
            (entryState.entryCanEdit || !isImportedFromChangeGearOrBamboo) && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">{`${
                  new Date(entryState.entryDate) < previousWorkableDay
                    ? "You cannot enter time past the last working day"
                    : "You cannot enter time further than this week"
                }`}</span>
              </div>
            )}
        </div>
        <div
          className={`d-flex flex-column ${
            !isMealBreak ? "col-sm-7" : "col-sm-3"
          }`}
          style={{ flexGrow: "1" }}
        >
          <label className="col-form-label">Project / Work order</label>
          <Select
            className={`me-3 ${
              entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryProjectWorkOrderValid
                ? "border-error"
                : ""
            }`}
            placeholder="Select a project, task or work order you are assigned to"
            options={actualProjectsWorkOrders}
            value={entryState.entryProjectWorkOrder}
            onChange={(project) =>
              handleChange(project, "entryProjectWorkOrder")
            }
            isDisabled={
              props.isLoadingProjects ||
              !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin, isTimeViewer) ||
              isHolidayFromBamboo ||
              isImportedFromChangeGearOrBamboo ||
              props.partialInactiveEntryProjectWorkOrder ||
              props.addMealBreakFromListView ||
              !entryState.entryCanEdit ||
              disabledByTimeViewer
            }
          />
          {entryState.isEntryTouched &&
            isSubmitted &&
            !entryState.entryProjectWorkOrderValid && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">Select project or work order</span>
              </div>
            )}
          {!isBetweenTwoDates(entryState.entryDate, null, today) &&
            isMealBreakOrNursing() &&
            ((isSuperTimeAdmin && activeUserId !== props.userIdParam) ||
              (isSupervisorOrPO && activeUserId !== props.userIdParam)) && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">
                  You cannot enter this type of time in the future
                </span>
              </div>
            )}
        </div>
        {!isMealBreak && (
          <div
            className="d-flex flex-column col-sm-2"
            style={{ maxWidth: "220px" }}
          >
            <label className="col-form-label">Hours</label>
            <InputNumberHours
              autoFocus={props.isModal}
              className={`me-3 ${
                (entryState.isEntryTouched &&
                  isSubmitted &&
                  !entryState.entryHoursValid) ||
                checkTotalHours()
                  ? "border-error"
                  : ""
              }`}
              hours={entryState.entryHours}
              setHours={(value) => handleChange(value, "entryHours")}
              disabled={
                isImportedFromChangeGearOrBamboo ||
                props.partialInactiveEntryProjectWorkOrder ||
                !entryState.entryCanEdit ||
                disabledByTimeViewer
              }
            />
            {entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryHoursValid &&
              !checkTotalHours() && (
                <div className="error-message">
                  <BiError className="error-icon" />
                  <span className="ms-1">{`${
                    entryState.entryHours === 0 || entryState.entryHours === ""
                      ? "Must be between 0.25 and 24 hours"
                      : entryState.entryProjectWorkOrder.value ===
                          PROJ_UNPAID_TIME_OFF_ID &&
                        entryState.entryTaskType.value ===
                          TASKTYPE_MEAL_BREAK_ID &&
                        Number(entryState.entryHours) > 1
                      ? "Meal break cannot exceed 1 hour"
                      : "Needs to be multiple of 0.25"
                  }`}</span>
                </div>
              )}
            {checkTotalHours() && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">Exceeds 24 in a day</span>
              </div>
            )}
            {isHolidayFromBamboo &&
              maxHoursAllowed < Number(entryState.entryHours) && (
                <div className="error-message">
                  <BiError className="error-icon" />
                  <span className="ms-1">
                    Hours: Can only be lower than the original imported
                  </span>
                </div>
              )}
          </div>
        )}
        {isMealBreak && (
          <>
            <div
              className="d-flex flex-column col-sm-3"
              style={{ maxWidth: "220px" }}
            >
              <label className="col-form-label">Meal break start</label>
              <Select
                className="me-3 has-clock-icon"
                options={mealBreakHours}
                value={mealBreakState.start}
                onChange={(value) => updateMealBreakHours(value, "start", true)}
                hasClockIcon={true}
                isDisabled={
                  isImportedFromChangeGearOrBamboo ||
                  !entryState.entryCanEdit ||
                  disabledByTimeViewer
                }
                placeholder=""
              />
            </div>
            <div
              className="d-flex flex-column col-sm-3"
              style={{ maxWidth: "220px" }}
            >
              <label className="col-form-label">Meal break end</label>
              <Select
                className="me-3 has-clock-icon"
                options={mealBreakHours}
                value={mealBreakState.end}
                onChange={(value) => updateMealBreakHours(value, "end", true)}
                hasClockIcon={true}
                isDisabled={
                  isImportedFromChangeGearOrBamboo ||
                  !entryState.entryCanEdit ||
                  disabledByTimeViewer
                }
                placeholder=""
              />
              {isMealBreak &&
                !entryState.isEntryValid &&
                entryState.entryDateValid &&
                isBetweenTwoDates(entryState.entryDate, null, today) && (
                  <div className="error-message">
                    <BiError className="error-icon" />
                    <span className="ms-1">
                      {!entryState.entryHoursValid
                        ? "Meal break cannot exceed 1 hour"
                        : "Only one meal break is allowed per day"}
                    </span>
                  </div>
                )}
            </div>
          </>
        )}
      </div>
      {isMealBreak && (
        <div className="card-meal-break my-3 d-flex flex-row">
          <BiErrorCircle className="meal-break-box-icon blue-text" />
          <span className="blue-text px-2 py-3">Meal Break</span>
          <span className="p-3">
            Non-exempt who work more than 6 hours in a day are required to take
            an unpaid, uninterrupted meal break of 30 minutes per ITX's
            established policies. Meal breaks are not counted toward hours
            worked. All non-exempt employees are required to track and record
            these breaks. Please enter the start and end time of your break.
          </span>
        </div>
      )}
      {!isMealBreak && (
        <div className="row mt-3">
          <div
            className="d-flex flex-column col-sm-3"
            style={{ maxWidth: "220px" }}
          >
            <label className="col-form-label">Task type</label>
            <Select
              className={`me-3 ${
                entryState.isEntryTouched &&
                isSubmitted &&
                !entryState.entryTaskTypeValid
                  ? "border-error"
                  : ""
              }`}
              options={
                isNursingMother ? taskTypeNursingMother : actualTaskTypes
              }
              value={entryState.entryTaskType}
              onChange={(value) => handleChange(value, "entryTaskType")}
              isDisabled={
                !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin, isTimeViewer) ||
                isHolidayFromBamboo ||
                isImportedFromChangeGearOrBamboo ||
                props.partialInactiveEntryProjectWorkOrder ||
                !entryState.entryCanEdit ||
                disabledByTimeViewer
              }
            />
            {entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryTaskTypeValid && (
                <div className="error-message">
                  <BiError className="error-icon" />
                  <span className="ms-1">Select task type</span>
                </div>
              )}
            {isTimeAdmin && (
              <>
                <div className="d-flex mt-4">
                  <input
                    className="form-check-input"
                    id="billable"
                    type="checkbox"
                    checked={entryState.entryIsBillable}
                    disabled={
                      isProjectWorkOrderInternal ||
                      isHolidayFromBamboo ||
                      isImportedFromChangeGearOrBamboo ||
                      props.partialInactiveEntryProjectWorkOrder ||
                      !entryState.entryCanEdit ||
                      disabledByTimeViewer
                    }
                    onChange={(e) =>
                      handleChange(e.target.checked, "entryIsBillable")
                    }
                  />
                  <label className="form-check-label ms-3">Billable</label>
                </div>
                {(!entryState.entryIsBillable ||
                  (entryState.entryProjectWorkOrder &&
                    entryState.entryProjectWorkOrder.isInternal)) && (
                  <div className="row mt-3">
                    <div
                      className="d-flex flex-column"
                      style={{ maxWidth: "220px" }}
                    >
                      <label className="col-form-label">
                        Non-billable reason
                      </label>
                      <Select
                        className={`me-3 ${
                          entryState.isEntryTouched &&
                          isSubmitted &&
                          !entryState.entryNonBillableReasonValid
                            ? "border-error"
                            : ""
                        }`}
                        options={nonBillableReasonsAfterFilter}
                        value={entryState.entryNonBillableReason}
                        onChange={(value) =>
                          handleChange(value, "entryNonBillableReason")
                        }
                        isDisabled={
                          isProjectWorkOrderInternal ||
                          !checkDataIsLoaded(
                            MetaTimesInfo,
                            isTimeAdmin,
                            isTimeViewer
                          ) ||
                          isHolidayFromBamboo ||
                          isImportedFromChangeGearOrBamboo ||
                          props.partialInactiveEntryProjectWorkOrder ||
                          !entryState.entryCanEdit ||
                          disabledByTimeViewer
                        }
                      />
                    </div>
                    {entryState.isEntryTouched &&
                      isSubmitted &&
                      !entryState.entryNonBillableReasonValid && (
                        <div className="error-message">
                          <BiError className="error-icon" />
                          <span className="ms-1">
                            Select Non-billable reason
                          </span>
                        </div>
                      )}
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className="d-flex flex-column col-sm-9"
            style={{ flexGrow: "1" }}
          >
            <label className="col-form-label">Description</label>
            <TextareaAutosize
              ref={textAreaRef}
              minRows={3}
              maxRows={6}
              maxLength={4000}
              className={`textarea-entry-box ${
                isHolidayFromBamboo ||
                isImportedFromChangeGearOrBamboo ||
                props.partialInactiveEntryProjectWorkOrder
                  ? "disabled"
                  : ""
              }`}
              placeholder="—"
              value={entryState.entryDescription}
              onChange={(e) => handleChange(e.target.value, "entryDescription")}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
              disabled={
                isHolidayFromBamboo ||
                isImportedFromChangeGearOrBamboo ||
                props.partialInactiveEntryProjectWorkOrder ||
                !entryState.entryCanEdit ||
                disabledByTimeViewer
              }
            />
          </div>
        </div>
      )}
      <div className="row mt-4">
        {!props.isModal && (
          <div className="clear-button pe-4" onClick={() => handleClear()}>
            Clear
          </div>
        )}
        {props.isModal && (
          <div
            className="d-flex justify-content-between"
            style={{ height: "40px" }}
          >
            <div>
              {props.editMode &&
                !props.multipleTimeEntries &&
                !props.isQueryPage &&
                !props.isListView &&
                (!isImportedFromChangeGearOrBamboo ||
                  (isImportedFromChangeGearOrBamboo &&
                    deletableFromBambooSuperAdmin)) &&
                !props.partialInactiveEntryProjectWorkOrder &&
                !disabledByTimeViewer &&
                iconDeleteEnabled(
                  props.activeEntry,
                  activeUserId,
                  props.userIdParam,
                  isSuperTimeAdmin,
                  isSupervisorOrPO,
                  isTimeViewer,
                  previousWorkableDay,
                  today,
                  lastWorkableDay
                ) && (
                  <div
                    className="delete-button mt-2"
                    onClick={() => props.deleteEntry(props.activeEntry)}
                  >
                    Delete
                  </div>
                )}
            </div>
            <div>
              <div className="d-flex">
                {(isHolidayFromBamboo || isImportedFromChangeGearOrBamboo) &&
                  actualSourceUrl &&
                  isImportedFromChangeGearOrBambooText !== CHANGE_GEAR && (
                    <Link
                      to={actualSourceUrl}
                      className="entry-text-imported text-decoration-none mt-3 me-5 other-links-hover"
                    >
                      From {isImportedFromChangeGearOrBambooText}
                    </Link>
                  )}
                {(isHolidayFromBamboo || isImportedFromChangeGearOrBamboo) &&
                  (!actualSourceUrl ||
                    isImportedFromChangeGearOrBambooText === CHANGE_GEAR) && (
                    <div className="entry-text-imported link-black mt-3 me-5">
                      From {isImportedFromChangeGearOrBambooText}
                    </div>
                  )}
                <button
                  className="btn button-secondary w-25"
                  onClick={props.btnCancel}
                >
                  Cancel
                </button>

                <button
                  className="btn btn-primary w-25 ms-3"
                  disabled={setSaveDisabled()}
                  onClick={() => clickHandler()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};
