import "../../../styles/main.module.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addToExcluded,
  deselectAll,
  removeFromExcluded,
  selectAll,
  selectMilestonesOrderedBy,
} from "./../../redux/reducers/billableItems.reducer";
import {
  currencyFormat,
  dateFormat,
} from "../../../SharedModule/utils/formatters";
import { useState } from "react";
import { Spinner } from "../../../SharedModule/components/Spinner";
import image from "./../../../styles/legacy/404.png";
import { NoContent } from "../../../SharedModule/components/NoContent";

const projectsBaseUrl = process.env.REACT_APP_ASUITE_PROJECT_URI;
const workOrderBaseUrl = process.env.REACT_APP_ASUITE_WO_URI;

export const Milestones = ({ loading }: { loading: boolean }) => {
  const [sort, setSort] = useState<any>({
    orderBy: "accountName",
    criteria: "asc",
    dataType: "string",
  });

  const sortBy = (orderBy: any, dataType: "string" | "date" | "number") => {
    let criteria: "asc" | "desc" = "asc";
    if (sort.orderBy === orderBy) {
      criteria = sort.criteria === "asc" ? "desc" : "asc";
    }
    setSort({
      orderBy,
      criteria,
      dataType,
    });
  };

  const { milestones, excluded } = useSelector(
    selectMilestonesOrderedBy(sort.orderBy, sort.criteria, sort.dataType),
    shallowEqual
  );

  const dispatch = useDispatch();

  if (loading)
    return (
      <div className="mt-5">
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      </div>
    );

  const isSelected = (id: number) => {
    return !excluded.some((item: any) => item.id === id);
  };

  const onClickCheckbox = (item: any) => {
    if (isSelected(item.id)) {
      dispatch(
        addToExcluded({
          type: "milestones",
          id: item.id,
        })
      );
    } else {
      dispatch(
        removeFromExcluded({
          type: "milestones",
          id: item.id,
        })
      );
    }
  };

  if (!loading && !milestones.length)
    return (
      <NoContent
        title="No Milestones"
        text="No milestones were found matching your query request."
        image={image}
        style={{ margin: "5% auto" }}
      />
    );

  return (
    <>
      <table className="table white-table-header-background mt-5">
        <thead className="align-middle">
          <tr className="sticky sticky-with-tabs">
            <th className="small fw-normal text-center border-dark border-top column-10">
              Select
              <div className="d-flex justify-content-center">
                <div
                  className="link-text mx-2"
                  onClick={() => dispatch(selectAll({ type: "milestones" }))}
                >
                  All
                </div>
                /
                <div
                  className="link-text mx-2"
                  onClick={() => dispatch(deselectAll({ type: "milestones" }))}
                >
                  None
                </div>
              </div>
            </th>
            <th
              className={`border-dark border-top fw-normal pointer column-20 ${
                sort.orderBy === "accountName" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("accountName", "string")}
            >
              Client & Project
            </th>
            <th
              className={`border-dark border-top fw-normal pointer column-15 ${
                sort.orderBy === "owner" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("owner", "string")}
            >
              Owner
            </th>
            <th
              className={`border-dark border-top fw-normal pointer column-15 ${
                sort.orderBy === "targetDate" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("targetDate", "date")}
            >
              Target Date
            </th>
            <th
              className={`border-dark border-top fw-normal pointer column-15 ${
                sort.orderBy === "description" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("description", "string")}
            >
              Description
            </th>
            <th
              className={`border-dark border-top fw-normal text-end pointer column-15 ${
                sort.orderBy === "amount"
                  ? "sorted-" + sort.criteria
                  : "sorted-column"
              }`}
              onClick={() => sortBy("amount", "number")}
            >
              Amount
            </th>
            <th
              className={`border-dark border-top fw-normal pointer column-10 ${
                sort.orderBy === "achieved" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("achieved", "string")}
            >
              Achieved
            </th>
          </tr>
        </thead>
        <tbody>
          {milestones.map((milestone: any) => (
            <tr key={milestone.id}>
              <td
                className="text-center pb-0"
                style={{ verticalAlign: "middle" }}
              >
                <div className="form-check d-inline-block">
                  <input
                    className="form-check-input"
                    id="hours"
                    type="checkbox"
                    disabled={!milestone.achieved}
                    checked={isSelected(milestone.id)}
                    onChange={() => onClickCheckbox(milestone)}
                  />
                </div>
              </td>
              <td>
                <div className="fw-bold">{milestone.accountName}</div>
                <a
                  className="other-links-hover text-decoration-none mt-1"
                  href={
                    milestone.projectId
                      ? projectsBaseUrl +
                        milestone.projectId +
                        "#milestoneSection"
                      : workOrderBaseUrl + milestone.workOrderId
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {milestone.projectName || milestone.workOrderDescription}
                </a>
              </td>
              <td>{milestone.owner}</td>
              <td>{dateFormat(milestone.targetDate)}</td>
              <td
                dangerouslySetInnerHTML={{
                  __html: milestone.description.replaceAll("\r\n", "<br>"),
                }}
              ></td>
              <td className="fw-bold text-end">
                {currencyFormat(milestone.amount)}
              </td>
              <td>
                <div>{milestone.achieved ? "Yes" : "No"}</div>
                <div className="mt-1">
                  {(milestone.achieved && dateFormat(milestone.achievedDate)) ||
                    ""}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
