import { User, UserAssigneesData, UserInfo } from "../../types";
import HttpService from "../../SharedModule/utils/api";
const BASE_URL = "/users/Users";

function getUsers(): Promise<User[]> {
  return HttpService.req(`${BASE_URL}`);
}

function getUserProfileInfo(mail: any): Promise<UserInfo> {
  return HttpService.req(`${BASE_URL}/user-details/${mail}`);
}

function getUserAssignees(id: string): Promise<UserAssigneesData> {
  return HttpService.req(`${BASE_URL}/${id}/assignees`);
}

function getUserDetails(id: string): Promise<any> {
  return HttpService.req(`${BASE_URL}/${id}/details`);
}

function getUserList(): Promise<any> {
  return HttpService.req(`${BASE_URL}/ListUsers`);
}

export const UsersService = {
  getUsers,
  getUserProfileInfo,
  getUserAssignees,
  getUserDetails,
  getUserList,
};
