import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getIsoFormatStringWithTimeZone,
  getLastWeekDay,
  isTodayBetween1stAnd3rdBusinessDay,
  NOW,
} from "../../../SharedModule/utils/dateUtils";
import { CostCenter, projectsOrWoWhitCostCenterId, UserToApprove } from "../../types";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";

type ApprovalTimeState = {
  isLoading: boolean;
  isInfoApprovalLoaded: boolean;
  costCentersApprovalTime: null;
  costCentersApprovalTimeComplete: Array<CostCenter> | [];
  projectsApprovalTime: Array<projectsOrWoWhitCostCenterId> | null;
  managersApprovalTime: null;
  filters: {
    projectOwner: Array<any> | null;
    costCenter: Array<any> | null;
    project: Array<any> | null;
    billableType: Array<any> | null;
    endDate: string;
  };
  usersList: UserToApprove[] | null;
  orderedBy: {
    orderBy: string;
    criteria: "asc" | "desc";
    dataType?: "string" | "date" | "number";
  };
};

const today = new Date();

const getEndDate = () => {
  return isTodayBetween1stAnd3rdBusinessDay()
    ? NOW.minus({ month: 1 }).endOf("month").startOf("day").toISO()
    : getIsoFormatStringWithTimeZone(getLastWeekDay(1, today));
};

const initialState: ApprovalTimeState = {
  isLoading: true,
  isInfoApprovalLoaded: false,
  costCentersApprovalTime: null,
  costCentersApprovalTimeComplete: [],
  projectsApprovalTime: null,
  managersApprovalTime: null,
  filters: {
    projectOwner: null,
    costCenter: null,
    project: null,
    billableType: null,
    endDate: getEndDate(),
  },
  usersList: [],
  orderedBy: {
    orderBy: "userName",
    criteria: "asc",
    dataType: "string",
  },
};

export const approvalTimeSlice = createSlice({
  name: "approvalTime",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setInfoApproval: (state, action) => {
      state.costCentersApprovalTime = action.payload.costCentersApprovalTime;
      state.projectsApprovalTime = action.payload.projectsApprovalTime;
      state.managersApprovalTime = action.payload.managersApprovalTime;
      state.isInfoApprovalLoaded = true;
    },
    setCostCentersApprovalTimeComplete: (state, action) => {
      state.costCentersApprovalTimeComplete = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    loadTimeEntriesInfo: (state, action) => {
      state.usersList = action.payload;
      state.isLoading = false;
    },
    changeOrder: (
      state,
      action: PayloadAction<{
        orderBy: string;
        dataType?: "string" | "date" | "number";
        criteria?: "asc" | "desc";
      }>
    ) => {
      const newOrderedBy: any = {
        ...action.payload,
        criteria: action.payload.criteria ? action.payload.criteria : "asc",
      };
      if (newOrderedBy.orderBy === state.orderedBy.orderBy) {
        newOrderedBy.criteria = action.payload.criteria
          ? action.payload.criteria
          : state.orderedBy.criteria === "asc"
          ? "desc"
          : "asc";
      }
      return { ...state, orderedBy: newOrderedBy };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setInfoApproval,
  setCostCentersApprovalTimeComplete,
  setFilters,
  loadTimeEntriesInfo,
  changeOrder,
  resetFilters,
} = approvalTimeSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.approvalTime.isLoading;
};

export const SelectFilters = (state: any) => {
  return state.approvalTime.filters;
};

export const SelectCostCentersApprovalTime = (state: any) => {
  return state.approvalTime.costCentersApprovalTime;
};

export const SelectCostCentersApprovalTimeComplete = (state: any) => {
  return state.approvalTime.costCentersApprovalTimeComplete;
};

export const SelectProjectsApprovalTime = (state: any) => {
  return state.approvalTime.projectsApprovalTime;
};

export const SelectManagersApprovalTime = (state: any) => {
  return state.approvalTime.managersApprovalTime;
};

export const SelectIsInfoApprovalLoaded = (state: any) => {
  return state.approvalTime.isInfoApprovalLoaded;
};

export const SelectUserList = ({
  approvalTime,
}: {
  approvalTime: ApprovalTimeState;
}) => {
  return { usersList: approvalTime.usersList };
};

export const selectOrderCriteria = ({
  approvalTime,
}: {
  approvalTime: ApprovalTimeState;
}) => approvalTime.orderedBy;

export const selectOrderedBy = createSelector(
  [SelectUserList, selectOrderCriteria],
  (approvalTime, orderCriteria) => {
    return {
      userList:
        (approvalTime.usersList &&
          approvalTime.usersList.length > 0 &&
          sortDataBy(
            approvalTime.usersList,
            orderCriteria.orderBy,
            orderCriteria.criteria,
            orderCriteria.dataType
          )) ||
        null,
    };
  }
);

export default approvalTimeSlice.reducer;
