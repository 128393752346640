import { Invoice } from "../../../types";
import {
  currencyFormat,
  dateFormat,
  decodeURIComponentSafe,
  quantityFormat,
} from "../../../SharedModule/utils/formatters";

const PROJECT_ID = 1;
const RECURRING_BILLING_ID = 3;

export const InvoiceBody = ({ invoice }: { invoice: Invoice }) => {

  let projectDetails: any[] = [];
  let workOrderDetails: any[] = [];

  // Separate details
  invoice.details.map((detail: any) => {
    if (detail.billingGroup.type.id === PROJECT_ID || detail.billingGroup.type.id === RECURRING_BILLING_ID){
      projectDetails.push(detail);
    } else {
      workOrderDetails.push(detail);
    }
    return false;
  });

  // If only has workOrderDetails, first detail should have a title
  let firstTitle = projectDetails.length === 0;

  // Sort work orders by externalId of billing Group
  workOrderDetails.sort((a,b) => a.billingGroup.externalId - b.billingGroup.externalId);
  // We should show first projects details and then work orders
  let renderDetails = projectDetails.concat(workOrderDetails);

  return (
    <table className="table grey-table-header-background caption-top mt-5">
      <thead className="align-middle">
        <tr>
          <th
            className="border-dark border-top fw-normal"
            style={{ width: "150px" }}>
            Date
          </th>
          <th
            className="border-dark border-top fw-normal"
            style={{ width: "300px" }}>
            Description
          </th>
          <th
            className="border-dark border-top fw-normal text-end"
            style={{ width: "96px" }}>
            Quantity
          </th>
          <th
            className="border-dark border-top fw-normal text-end"
            style={{ width: "96px" }}>
            Rate
          </th>
          <th
            className="border-dark border-top fw-normal text-end"
            style={{ width: "96px" }}>
            Amount
          </th>
        </tr>
      </thead>
      <tbody>
        {renderDetails.length > 0 &&
          renderDetails.map((detail: any, index: number) => {
            const row = (
              <tr key={detail.id}>
                <td>{dateFormat(detail.date)}</td>
                <td
                  style={{ width: "300px", wordBreak: "break-word" }}
                  dangerouslySetInnerHTML={{
                    __html: decodeURIComponentSafe(detail.description.replaceAll("\r\n","<br>")),
                  }}
                ></td>
                <td className="text-end">{quantityFormat(detail.quantity)}</td>
                <td className="text-end">{currencyFormat(detail.rate)}</td>
                <td className="fw-bold text-end">
                  {currencyFormat(detail.amount)}
                  {detail.taxes && " T"}
                </td>
              </tr>
            );

            // Set title for resources
            let title;
            const additionalResourcesTitle = (
              <tr key={detail.billingGroup.externalId}>
                <td colSpan={5} className="fw-bold fst-italic ps-4">
                  Additional Resources
                </td>
              </tr>
            );

            // Ask for diff work orders and set same title for same externalId
            // also ask for first title in case we only hav work orders (just once with 'FirstTitle')
            if ((renderDetails[index - 1] &&
              detail.billingGroup.externalId !== renderDetails[index - 1].billingGroup.externalId)
              || firstTitle){
              if (detail.billingGroup.type.id === 4){
                title = (
                  <>
                    {/* avoid space in first row */}
                    {!firstTitle && <tr><td colSpan={5} style={{ border: 'none'}}></td></tr>}
                    <tr key={detail.billingGroup.externalId}>
                      <td colSpan={5} className="fw-bolder">
                        WO {detail.billingGroup.externalId}
                        {" - "}
                        {detail.billingGroup.name.toUpperCase()}
                      </td>
                    </tr>
                  </>                  
                );
                firstTitle = false;
              } else {
                // if first title true and additional resources, should not have first title
                if (firstTitle){
                  firstTitle = false;
                } else {
                  title = additionalResourcesTitle;
                }
              }
            }

            // type.id === 2 -> Resources
            if (renderDetails[index - 1] &&
                detail.type.id === 2 &&
                renderDetails[index - 1].type.id !== 2){
              if (title !== additionalResourcesTitle) {
                title = [title, additionalResourcesTitle];
              }
            }

            return [title, row];
          })}
      </tbody>
    </table>
  );
};
