import { dateFormatWithHours } from "../../SharedModule/utils/formatters";
import { EntryAction } from "../types";
import { formatAuditNewChanges } from "../utils/timesUtils";
import { Spinner } from "../../SharedModule/components/Spinner";

export const ModalMoreInfoTimeEntry = (props) => {
  return (
    <>
      <div className="row">
        <h4>Time entry details</h4>
      </div>
      <hr style={{ width: "100%" }} />
      {props.isLoadingActions && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!props.isLoadingActions && (
        <div className="text-overflow">
          {props.actions &&
            props.actions.map((action: EntryAction, index: number) => {
              let changes: any[] = [];
              if (action.oldValues) {
                changes = formatAuditNewChanges(
                  action.oldValues,
                  action.newValues
                );
              }
              return (
                <>
                  {index !== 0 && <hr style={{ width: "100%" }} />}
                  <div className="row" style={{ maxWidth: "100%" }}>
                    <div className="modal-info-action">{action.action}</div>
                    {action.action === "Edited" &&
                      changes.map((change) => (
                        <div className="modal-info-action-text mt-1 ms-4">
                          {change}
                        </div>
                      ))}
                    <div className="modal-info-action-text mt-2">
                      {action.userName} on{" "}
                      {dateFormatWithHours(action.entryDateEst)} (ET)
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      )}
      <hr style={{ width: "100%" }} />
      <div className="mt-3 d-flex justify-content-end">
        <button
          className="btn btn-primary w-25"
          onClick={props.btnCancel}
          disabled={props.isLoadingActions}
        >
          Close
        </button>
      </div>
    </>
  );
};
